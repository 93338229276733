import styled from 'styled-components'

export const GameCardContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s;
  padding: 12px;
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
`

export const GameCardContainerV2 = styled.div`
  background: ${(props) => props.theme.secondary};
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  border-radius: 5px;
  /* max-height: 262px; */
  overflow: hidden;
`
