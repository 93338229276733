import { InputHTMLAttributes } from 'react'
import { SearchBarContainer } from './style'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'

interface ISearchBar extends InputHTMLAttributes<HTMLInputElement> {
  borderColor?: string
  borderRadius?: string
  inputClassName?: string
}

const SearchBar = (props: ISearchBar) => {
  const { borderColor, borderRadius, inputClassName, ...rest } = props
  return (
    <SearchBarContainer
      inputClassName={inputClassName}
      borderColor={borderColor}
      borderRadius={borderRadius}
    >
      <input className='input' type='text' {...rest} />
      <SearchIcon data-testid='searchIcon' />
    </SearchBarContainer>
  )
}

export default SearchBar
