import { GameCardContainer, GameCardContainerV2 } from './style'
import { Col, Row, SkeletonWrapper } from 'shared/styled'

interface ICardSkeletonLoader {
  width?: string
  height?: string
  borderRadius?: string
  variant?: 'skeleton2' | 'skeleton3' | 'skeleton4' | 'tableRow' | 'subTableRow'
}

const CardSkeletonLoader = (props: ICardSkeletonLoader) => {
  const { width, borderRadius, height, variant } = props

  switch (variant) {
    case 'skeleton2':
      return (
        <GameCardContainerV2 data-testid='game-card-container-v2'>
          <SkeletonWrapper
            width={width}
            height={height}
            borderRadius={borderRadius}
            data-testid='skeleton-wrapper-2-1'
          />
          <Row margin='10px 0px' marginLeft='12px' data-testid='row-2-1'>
            <SkeletonWrapper
              width={'120px'}
              height={'20px'}
              borderRadius={borderRadius}
            />
          </Row>
        </GameCardContainerV2>
      )
    case 'skeleton3':
      return (
        <Col data-testid='col-3'>
          <SkeletonWrapper
            width={width}
            height={height}
            borderRadius={'20px'}
            data-testid='skeleton-wrapper-3-1'
          />
          <Row margin='10px 0px' data-testid='row-3-1'>
            <SkeletonWrapper
              width={'120px'}
              height={'20px'}
              borderRadius={borderRadius}
              data-testid='skeleton-wrapper-3-2'
            />
          </Row>
          <Row data-testid='row-3-2'>
            <SkeletonWrapper
              width={'170px'}
              height={'20px'}
              borderRadius={borderRadius}
              data-testid='skeleton-wrapper-3-3'
            />
          </Row>
        </Col>
      )
    case 'skeleton4':
      return (
        <Row align='center' data-testid='row'>
          <SkeletonWrapper
            width={'135px'}
            height={'100px'}
            borderRadius={borderRadius}
            data-testid='skeleton-wrapper-4-1'
          />
          <Col>
            <SkeletonWrapper
              width={'120px'}
              height={'20px'}
              borderRadius={borderRadius}
              data-testid='skeleton-wrapper-4-2'
            />
            <SkeletonWrapper
              width={'30px'}
              height={'20px'}
              borderRadius={borderRadius}
              data-testid='skeleton-wrapper-4-3'
            />
          </Col>
        </Row>
      )
    case 'tableRow':
      return (
        <Row width='100%' align='center' data-testid='row'>
          <Col width='100%' data-testid='col'>
            <SkeletonWrapper
              data-testid='skeleton-wrapper'
              width={'100%'}
              height={'60px'}
              borderRadius={borderRadius}
            />
          </Col>
        </Row>
      )

    case 'subTableRow':
      return (
        <Row width='100%' align='center' data-testid='row'>
          <Col width='100%' data-testid='col'>
            <SkeletonWrapper
              data-testid='skeleton-wrapper'
              width={'100%'}
              height={'44px'}
              borderRadius={borderRadius}
            />
          </Col>
        </Row>
      )
    default:
      return (
        <GameCardContainer>
          <SkeletonWrapper
            width={width}
            height={height}
            borderRadius={borderRadius}
            data-testid='skeleton-wrapper-5-1'
          />
          <Row margin='10px 0px' data-testid='row'>
            <SkeletonWrapper
              width={width}
              height={'20px'}
              borderRadius={borderRadius}
              data-testid='skeleton-wrapper-5-2'
            />
          </Row>
        </GameCardContainer>
      )
  }
}

export default CardSkeletonLoader
