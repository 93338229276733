import {
  CheckboxFilterConfig,
  DateRangeFilterConfig,
  DropdownFilterConfig,
  InputFilterConfig,
  RadioFilterConfig,
} from 'utils/reportType'

interface StaticFilterConfig {
  type: ''
  label: string
  options: string[]
  dataKey?: string
  disabled?: boolean
}

interface RangeFilterConfig {
  type: 'range'
  label: string
  min: number
  max: number
  isFloor?: boolean
  step?: number
  unit?: string
  dataKey?: string
  disabled?: boolean
}

interface InputNumFilterConfig {
  type: 'inputNum'
  label: string
  options: string[]
  dataKey?: string
  disabled?: boolean
}

export type FilterConfig =
  | StaticFilterConfig
  | RangeFilterConfig
  | DropdownFilterConfig
  | CheckboxFilterConfig
  | DateRangeFilterConfig
  | RadioFilterConfig
  | InputFilterConfig
  | InputNumFilterConfig

export const filters: FilterConfig[] = [
  {
    type: '',
    label: 'First Name',
    options: [],
    dataKey: 'first_name',
  },
  {
    type: '',
    label: 'Last Name',
    options: [],
    dataKey: 'last_name',
  },
  {
    type: '',
    label: 'Display Name',
    options: [],
    dataKey: 'display_name',
  },
  {
    type: '',
    label: 'Email',
    options: [],
    dataKey: 'email',
  },
  {
    type: '',
    label: 'User ID',
    options: [],
    dataKey: 'users_id',
  },
  {
    type: 'dateRange',
    disabled: true,
    label: 'Event Time',
    options: ['1D', '1W', '1M', 'Custom'],
    dataKey: 'event_time',
  },
  {
    type: 'dropdown',
    label: 'User type',
    headerText: 'Select user type',
    enableSearch: false,

    options: [
      { name: 'Player', slug: 4, checked: false },
      { name: 'Publisher', slug: 3, checked: false },
    ],
    dataKey: 'user_type',
  },
  {
    type: 'radio',
    label: 'Currency Type',
    disabled: false,
    options: ['ETH', 'SOL'],
    dataKey: 'currency_type',
  },
  {
    type: 'range',
    label: 'Gross Value',
    min: 0,
    max: 1000,
    step: 10,
    unit: 'units',
    dataKey: 'gross_value',
  },
  {
    type: 'range',
    label: 'Value',
    min: 0,
    max: 1000,
    step: 10,
    unit: 'units',
    dataKey: 'value',
  },
  {
    type: 'range',
    label: 'Points Tallied',
    min: 0,
    max: 1000,
    step: 10,
    isFloor: true,
    unit: '',
    dataKey: 'pointed_tailled',
  },
  {
    type: 'dropdown',
    headerText: 'Select quest(s) consumed',
    enableSearch: true,
    label: 'Quest(s) Consumed',
    options: [{ name: '', slug: '', checked: false }],
    dataKey: 'quest_consumed',
  },
  {
    type: 'dropdown',
    disabled: true,
    enableSearch: true,
    headerText: 'Select Event Type ID',
    label: 'Event Type ID',
    options: [{ name: '', slug: '', checked: false }],
    dataKey: 'event_type_id',
  },

  {
    type: 'input',
    label: 'Session ID',
    options: [],
    dataKey: 'session_id',
  },
  {
    type: 'dropdown',
    headerText: 'Marketplace ID',
    label: 'Marketplace ID',
    options: [
      { name: 'Xstrela', slug: 1, checked: false },
      { name: 'Opensea', slug: 2, checked: false },
    ],
    enableSearch: false,

    dataKey: 'marketplace_id',
  },
  {
    type: '',
    label: 'Contextual Info',
    options: [],
    dataKey: 'contextual_info',
  },
  {
    type: 'dropdown',
    label: 'User Agent',
    headerText: 'User Agent',
    enableSearch: false,
    options: [
      { name: 'Google Chrome', slug: 'Chrome', checked: false },
      { name: 'Mozilla Firefox', slug: 'Mozilla', checked: false },
      { name: 'Safari', slug: 'Safari', checked: false },
      { name: 'Apple Web Kit', slug: 'AppleWebKit', checked: false },
      { name: 'Microsoft Edge', slug: 'Microsoft', checked: false },
      { name: 'Opera', slug: 'Opera', checked: false },
      { name: 'Firefox Mobile', slug: 'Firefox', checked: false },
      { name: 'Gmail', slug: 'Gmail', checked: false },
      { name: 'curl', slug: 'curl', checked: false },
      { name: 'wget', slug: 'wget', checked: false },
      { name: 'Postman', slug: 'Postman', checked: false },
      { name: 'Googlebot', slug: 'Googlebot', checked: false },
      { name: 'Bingbot', slug: 'Bingbot', checked: false },
      { name: 'Yahoo! Slurp', slug: 'Yahoo! Slurp', checked: false },
    ],
    dataKey: 'user_agent',
  },
  {
    type: 'input',
    label: 'Device ID',
    options: [],
    dataKey: 'device_id',
  },
  {
    type: 'input',
    label: 'Ipv4',
    options: [],
    dataKey: 'ipv4',
  },
  {
    type: 'input',
    label: 'Ipv6',
    options: [],
    dataKey: 'ipv6',
  },
]
export const columnsToRemove = []
