import { ReactComponent as ArrowSwapIcon } from 'assets/icons/arrow-swap.svg'
import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-swap-up.svg'
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-swap-down.svg'

interface ISortIcon {
  sortOrder: 'asc' | 'desc' | ''
  onClick: any
  id?: string
}

const SortIcon = (props: ISortIcon) => {
  const { sortOrder, onClick, id } = props

  const renderIcon = () => {
    switch (sortOrder) {
      case 'asc':
        return (
          <ArrowUpIcon
            className='arrowIcon'
            onClick={onClick}
            data-testid={id ? `${id}_upArrow` : '_upArrow '}
          />
        )
      case 'desc':
        return (
          <ArrowDownIcon
            className='arrowIcon'
            onClick={onClick}
            data-testid={id ? `${id}_downArrow` : '_downArrow '}
          />
        )
      case '':
      default:
        return (
          <ArrowSwapIcon
            className='sortIcon'
            onClick={onClick}
            data-testid={id ? `${id}_swapArrow` : '_swapArrow '}
          />
        )
    }
  }

  return <>{renderIcon()}</>
}

export default SortIcon
