import { TableHeadText } from 'shared/typography'

export const DEBOUNCE_TIME = 300 // in miliseconds

export const tableHead: any = [
  {
    Header: () => <TableHeadText>Event Type</TableHeadText>,
    accessor: 'col1',
  },
  {
    Header: () => <TableHeadText textAlign='start'>Item</TableHeadText>,
    accessor: 'col2',
  },
  {
    Header: () => (
      <TableHeadText textAlign='start'>Marketplace origination</TableHeadText>
    ),
    accessor: 'col3',
  },
  {
    Header: () => <TableHeadText textAlign='start'>Price</TableHeadText>,
    accessor: 'col4',
  },
  {
    Header: () => <TableHeadText textAlign='start'>Chain</TableHeadText>,
    accessor: 'col5',
  },
  {
    Header: () => <TableHeadText textAlign='start'>From</TableHeadText>,
    accessor: 'col6',
  },
  {
    Header: () => <TableHeadText textAlign='start'>To</TableHeadText>,
    accessor: 'col7',
  },
  {
    Header: () => <TableHeadText textAlign='start'>Time</TableHeadText>,
    accessor: 'col8',
  },
]
