import { Col, Row } from 'shared/styled'
import styled from 'styled-components'

export const Container = styled(Row)<{ showOptions: boolean }>`
  border: 1px solid ${(props) => props.theme.primaryText};
  gap: 10px;
  padding: 6px 12px;
  border-radius: 50px;
  align-items: center;
  height: 37px;

  .arrowDownIcon {
    height: 12px;
    width: 12px;
    transition: rotate 0.5s;
    rotate: ${(props) => props.showOptions && `-180deg`};
  }
`

export const OptionHeader = styled(Row)`
  position: relative;
`

export const OptionContainer = styled(Col)`
  top: 130%;
  left: -8px;
  position: absolute;
  background-color: ${(props) => props.theme.borderColor};
  width: 60px;
  border-radius: 6px;
  padding-left: 6px;
  padding-bottom: 6px;
  z-index: 1000;

  p {
    padding: 2px 4px;
    cursor: pointer;
  }
`

export const Wrapper = styled(Row)`
  svg > path {
    stroke: ${(props) => props.theme.primary};
    transition: stroke 0.3s ease-in;
  }
  button {
    :hover {
      svg > path {
        stroke: ${(props) => props.theme.secondary};
      }
    }
  }
`
