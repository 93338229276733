import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Row } from 'shared/styled'
import { BoldText3 } from 'shared/typography'
import styled from 'styled-components'
import { SubSidebarContainer } from './style'
import { capitalizeFirstLetter } from 'utils/helpers/stringHelpers'
import { screenSizes } from 'styles/theme'

const TimelineContainer = styled.div`
  position: relative;
  padding: 10px 0;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 22%;
    left: 5px;
    width: 1.6px;
    height: 45%;
    background-color: ${(props) => props.theme.borderColor};
  }
`

const TimelineItemContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding-left: 28px;
`

const Circle = styled.div<{ isActive: boolean }>`
  position: absolute;
  left: 0;
  top: 35%;
  width: 12px;
  height: 12px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.primary : '#e0e0e0'};
  border-radius: 50%;
  cursor: pointer;
`

const Content = styled.div<{ isActive: boolean }>`
  background-color: ${({ theme, isActive }) => (isActive ? theme.primary : '')};
  border-radius: 8px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.primary : theme.secondary};

  @media (min-width: ${screenSizes.mediaL}px) {
    color: ${({ theme, isActive }) =>
      isActive ? theme.secondary : theme.primaryText};
  }
`
interface TimelineItemProps {
  item: {
    path: string
    label: string
    icon: React.ReactNode
    isAccordion?: boolean
    items?: TimelineItemProps['item'][] // Nested items
  }
  isActive: boolean
  handleLabelClick: Function
}

const TimelineItem: React.FC<TimelineItemProps> = ({
  item,
  isActive,
  handleLabelClick,
}) => {
  const getNavLinkClassName = (isActive: boolean, label: string | null) => {
    return `${
      isActive ? 'active-route animate__animated' : 'inactive-route'
    } navLink icon-${label} nested-sidebar`
  }

  return (
    <TimelineItemContainer>
      <Circle isActive={isActive} />
      <Content isActive={isActive}>
        <SubSidebarContainer>
          <Row
            width='100%'
            gap='12px'
            align='center'
            padding='0 0 0 20px'
            key={item.path}
            className={getNavLinkClassName(isActive, item.label)}
            onClick={() => handleLabelClick(item.label)}
          >
            <BoldText3 className='subItem'>
              {capitalizeFirstLetter(item.label)}
            </BoldText3>
          </Row>
        </SubSidebarContainer>
      </Content>
    </TimelineItemContainer>
  )
}

interface TimelineItemProps {
  label: string
  path: string
}

interface TimelineProps {
  items: TimelineItemProps[]
  rootPath: string
}

const Timeline: React.FC<TimelineProps> = ({ items, rootPath }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tab = searchParams.get('tab') ?? ''

  const [expandedItem, setExpandedItem] = useState<string | null>(null)

  useEffect(() => {
    setExpandedItem(tab)
  }, [tab])

  const handleLabelClick = (label: string) => {
    navigate(`${rootPath}?tab=${label}`)
    setExpandedItem((prev) => (prev === label ? null : label))
  }

  return (
    <TimelineContainer>
      {items?.map((item) => (
        <TimelineItem
          key={item.path}
          //@ts-ignore
          item={item}
          isActive={expandedItem === item.label}
          handleLabelClick={handleLabelClick}
        />
      ))}
    </TimelineContainer>
  )
}

export default Timeline
