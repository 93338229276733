import { ReactComponent as GridIcon } from 'assets/icons/grid-2.svg'
import { ReactComponent as PointCollected } from 'assets/icons/point-collected.svg'
import { ReactComponent as ReorderDotsIcon } from 'assets/icons/reorder-dots.svg'
import { ReactComponent as QuestIcon } from 'assets/icons/total-quest.svg'
import placeholderIcon from 'assets/images/quest-placeholder.svg'
import { debounce } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import SearchBar from 'shared/SearchBar'
import CustomModal from 'shared/custom-modal-responsive'
import { CustomTable } from 'shared/customTable'
import { ExportQuestReport } from 'shared/exportReport/ExportQuestReport'
import NoData from 'shared/noData'
import { DetailsContainer, DetailsTop } from 'shared/pageDetailStyles'
import { ContentContainer, PageWrapper } from 'shared/pageStyles'
import Pagination from 'shared/pagination/Pagination'
import { RenderGameCategory } from 'shared/renderGameCategory/RenderGameCategory'
import { ReportTableContainer } from 'shared/reportStyles'
import SortIcon from 'shared/sortIcon/SortIcon'
import { Spinner } from 'shared/spinner/Spinner'
import { Col, Row, TableHeadItem } from 'shared/styled'
import { ReactComponent as I } from 'assets/icons/i.svg'
import {
  BoldText13,
  BoldText4,
  BoldText5,
  Heading3,
  Heading4,
  TableHeadText,
} from 'shared/typography'
import { getNextSortOrder, handlePageChange } from 'utils'
import { GetQuestDetail, GetQuestReport } from 'utils/apis/quests/quest'
import { handleImageError } from 'utils/helpers/fileHelpers'
import {
  IQuestDetail,
  IQuestList,
  IQuestReportFilters,
  IQuestSortOrder,
  ITableColumn,
  ITableColumnConfig,
  ITableData,
} from 'utils/type'
import { DEBOUNCE_TIME } from '../activity/constants'

import { PAGE_LIMIT } from '../gamePlayers/constants'
import * as Styles from './style'
import { columnConfig } from './tableConfig'
import { CustomColumnModal } from './components/CustomColumnModal'
import { convertUTCTimeByTimeZone } from 'utils/helpers/dateTimeHelpers'
import Tooltip from 'shared/tooltip/Tooltip'

export const QuestReport = () => {
  const [loader, setLoader] = useState(false)
  const [questDetails, setQuestDetails] = useState<IQuestDetail>({
    first_name: '',
    last_name: '',
    display_name: '',
    img_url: '',
    user_type: [],
    total_quest: 0,
    completed_quest: 0,
    in_progress_quest: 0,
    points_collected: 0,
  })
  const [questList, setQuestList] = useState<IQuestList[]>([])
  const [tableHead, setTableHead] = useState<ITableColumnConfig[]>([])
  const [tableData, setTableData] = useState<ITableData[]>([])
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState<number>(1)
  const [search, setSearch] = useState('')
  const [tableColumns, setTableColumns] = useState<string[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)
  const [tableColumn, setTableColumn] = useState<ITableColumnConfig[]>([])
  const [showCustomizeColumns, setShowCustomizeColumns] =
    useState<boolean>(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [filtersOptions, setFiltersOptions] = useState<IQuestReportFilters>({
    page: page,
    limit: PAGE_LIMIT,
  })
  const getInitialSortOrders = (): IQuestSortOrder => {
    const initialSortOrders: IQuestSortOrder = {} as IQuestSortOrder // Type assertion

    columnConfig.forEach((column) => {
      initialSortOrders[column.dataKey as keyof IQuestSortOrder] = '' // Ensure all columns have an empty initial sort order
    })

    return initialSortOrders
  }

  const [sortOrders, setSortOrders] = useState<IQuestSortOrder>(
    getInitialSortOrders()
  )

  const url = window.location.href
  const id: string = url.split('/').pop() ?? ''

  const detectScroll = (e: React.UIEvent) => {
    const newScrollPosition = (e.currentTarget as HTMLDivElement).scrollLeft

    setScrollPosition(newScrollPosition)
  }

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setFiltersOptions((prev: IQuestReportFilters) => ({
          ...prev,
          search: e.target.value.trim(),
          page: 1,
        }))
      }, DEBOUNCE_TIME),
    []
  )
  useEffect(() => {
    /**
     * This useEffect is used to set the horizontal scroll to its previous value to maintain the scroll position after table rendering.
     *
     * Sets the scroll position of the table container after rendering.
     * This function is triggered by the 'scrollPosition' state variable.
     * It uses the 'tableContainer' element to set the scroll position.
     * The scroll position is set to the value of the 'scrollPosition' state variable.
     * The scroll position is only set if the 'tableContainer' element exists, the 'transactionLogList' is not empty, and the 'loader' is false.
     * This function is called after each render.
     *
     * @param None
     * @returns None
     */
    const tableContainer = document.getElementById('tableContainer')

    if (tableContainer && questList?.length && loader) {
      tableContainer.scrollLeft = scrollPosition
    }
  }, [scrollPosition, questList, loader])
  const getQuestData = async () => {
    setLoader(true)
    try {
      const res = await GetQuestReport({ user_id: id, ...filtersOptions })

      setQuestList(res?.data)

      setTotalPages(res?.total_page)
    } catch (error) {
      setTotalPages(1)
    } finally {
      setLoader(false)
    }
  }
  useEffect(() => {
    if (id) getQuestDetails()
  }, [id])
  const getQuestDetails = async () => {
    try {
      const res = await GetQuestDetail(id)

      setQuestDetails(res)
    } catch (error) {}
  }
  useEffect(() => {
    if (id) getQuestData()
  }, [id, filtersOptions])

  useEffect(() => {
    if (tableColumns?.length) {
      // Step 1: Filter and order columns based on `tableColumns` array
      let filteredColumns = columnConfig.filter((column) =>
        tableColumns.includes(column.dataKey as keyof IQuestList)
      )

      // Step 2: Sort `filteredColumns` according to `tableColumns` order
      const priorityMap = new Map<string, number>(
        tableColumns.map((key, index) => [key, index])
      )

      filteredColumns = filteredColumns.sort((a, b) => {
        const indexA = priorityMap.get(a.dataKey) ?? -1
        const indexB = priorityMap.get(b.dataKey) ?? -1
        return indexA - indexB
      })

      // Step 3: Ensure unique accessors by using the column dataKey as part of the accessor
      const updatedFilteredColumns: ITableColumn[] = filteredColumns.map(
        (column, index) => ({
          ...column,
          accessor: `col_${column.dataKey}_${index + 1}`,
          dataKey: column.dataKey as keyof IQuestList,
        })
      )

      setTableColumn(updatedFilteredColumns)
    } else {
      // Ensure unique accessors in the default configuration
      const uniqueColumns: ITableColumn[] = columnConfig.map(
        (column, index) => ({
          ...column,
          accessor: `col_${column.dataKey}_${index + 1}`,
          dataKey: column.dataKey as keyof IQuestList,
        })
      )

      setTableColumn(uniqueColumns)
    }
  }, [tableColumns])

  const handleSort = (
    column: keyof IQuestSortOrder,
    item: { value?: string | null }
  ) => {
    const order = getNextSortOrder(sortOrders[column])

    setSortOrders((prevSortOrders: IQuestSortOrder) => ({
      ...Object.keys(prevSortOrders).reduce((acc, key) => {
        acc[key as keyof IQuestSortOrder] = key === column ? order : ''
        return acc
      }, {} as IQuestSortOrder),
    }))

    setPage(1)

    setFiltersOptions(
      (prev: IQuestReportFilters) =>
        ({
          ...prev,
          page: 1,
          sort_by: item?.value || null,
        } as IQuestReportFilters)
    )
  }

  const createSortHandler =
    (
      id: keyof IQuestSortOrder,
      sortOrders: IQuestSortOrder,
      handleSort: Function
    ) =>
    () => {
      let sortOrder
      if (sortOrders[id] === '') {
        sortOrder = {
          name: `${id} ascending`,
          value: `${id.toUpperCase()}_ASC`,
        }
      } else if (sortOrders[id] === 'asc') {
        sortOrder = {
          name: `${id} descending`,
          value: `${id.toUpperCase()}_DESC`,
        }
      } else {
        sortOrder = { name: '', value: null }
      }

      handleSort(id, sortOrder)
    }

  useEffect(() => {
    const columns = tableColumn?.map((column) => ({
      Header: () => (
        <TableHeadItem key={column?.accessor} gap='4px' align='center'>
          <ReorderDotsIcon />
          <TableHeadText textAlign='center'>{column?.title}</TableHeadText>
          {column?.sortable ? (
            <SortIcon
              id={column?.dataKey}
              sortOrder={
                column?.dataKey && column.dataKey in sortOrders
                  ? (sortOrders[column.dataKey as keyof IQuestSortOrder] as
                      | ''
                      | 'desc'
                      | 'asc')
                  : ''
              }
              onClick={
                column?.dataKey
                  ? createSortHandler(
                      column.dataKey as keyof IQuestSortOrder,
                      sortOrders,
                      handleSort
                    )
                  : undefined
              }
            />
          ) : null}
          {column?.i && (
            <Row marginLeft='10px'>
              <Tooltip direction='left' text={column?.i}>
                {<I />}
              </Tooltip>
            </Row>
          )}
        </TableHeadItem>
      ),
      ...column,
    }))
    setTableHead(columns)
  }, [tableColumn, sortOrders])

  const setTableBody = () => {
    if (questList?.length > 0) {
      const tableData = questList.map((ele: IQuestList) => {
        const tblData = tableColumn?.reduce(
          (acc: Record<string, JSX.Element>, col: ITableColumn) => {
            switch (col.dataKey) {
              case 'quest_start_time':
                acc[col?.accessor] = (
                  <BoldText5 minWidth='200px'>
                    {ele?.quest_start_time
                      ? convertUTCTimeByTimeZone(ele?.quest_start_time)
                      : '--'}
                  </BoldText5>
                )
                break
              case 'quest_end_time':
                acc[col?.accessor] = (
                  <BoldText5 minWidth='200px'>
                    {ele?.quest_end_time
                      ? convertUTCTimeByTimeZone(ele?.quest_end_time)
                      : '--'}
                  </BoldText5>
                )
                break
              case 'quest_collection':
                acc[col?.accessor] = ele?.quest_collection?.length ? (
                  <Row minWidth='200px'>
                    <RenderGameCategory
                      categories={ele?.quest_collection?.map((item: string) => {
                        return { name: item, slug: item }
                      })}
                    />
                  </Row>
                ) : (
                  <BoldText5 minWidth='200px'>--</BoldText5>
                )
                break
              case 'quest_category':
                acc[col?.accessor] = ele?.quest_category?.length ? (
                  <Row minWidth='200px'>
                    <RenderGameCategory
                      categories={ele?.quest_category?.map((item: string) => {
                        return { name: item, slug: item }
                      })}
                    />
                  </Row>
                ) : (
                  <BoldText5 minWidth='200px'>--</BoldText5>
                )
                break
              default:
                acc[col?.accessor] = (
                  <BoldText5 minWidth='200px'>
                    {/* @ts-ignore */}
                    {ele?.[col?.dataKey] ?? '--'}
                  </BoldText5>
                )
            }
            return acc
          },
          {}
        )
        return tblData
      })
      setTableData(tableData)
      setLoader(false)
    } else setTableData([])
  }
  useEffect(() => {
    if (tableColumns.length) {
      const updatedColumns = tableColumns
        .map((key) => columnConfig.find((col) => col.dataKey === key))
        .filter(Boolean) as ITableColumnConfig[] // Ensure filtering out undefined values

      setTableColumn(updatedColumns)
    } else {
      setTableColumn(columnConfig as ITableColumnConfig[])
    }
  }, [tableColumns])

  useEffect(() => {
    setTableBody()
  }, [questList, tableColumn]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderTable = questList?.length ? (
    <Col>
      <ReportTableContainer id='tableContainer' onScroll={detectScroll}>
        <CustomTable data={tableData} columns={tableHead} />
      </ReportTableContainer>
      <Row justify='end' padding='15px'>
        {!loader && (
          <Pagination
            pageLimit={5}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={(pageNumber) =>
              handlePageChange(
                pageNumber,
                setPage,
                filtersOptions,
                setFiltersOptions
              )
            }
          />
        )}
      </Row>
    </Col>
  ) : (
    <Row justify='center' align='center' padding='20px'>
      <NoData />
    </Row>
  )
  return (
    <PageWrapper>
      <ContentContainer>
        <DetailsContainer>
          <Styles.AssetImage
            src={questDetails?.img_url ?? placeholderIcon}
            alt='asset-image'
            onError={handleImageError}
          />
          <Col width='100%' gap='17px'>
            <DetailsTop>
              <Col width='100%'>
                <Col gap='10px'>
                  <Row align='center'>
                    <Heading3>
                      {questDetails?.display_name ?? questDetails?.first_name}
                    </Heading3>
                  </Row>
                </Col>
              </Col>
            </DetailsTop>

            <Row wrap='wrap'>
              <Styles.DetailItem>
                <Row>
                  <QuestIcon className='details-icon' />
                  <Col>
                    <BoldText4>Total Quest</BoldText4>
                    <BoldText13>{questDetails?.total_quest}</BoldText13>
                  </Col>
                </Row>
              </Styles.DetailItem>

              <Styles.DetailItem>
                <Row>
                  <QuestIcon className='details-icon' />

                  <Col>
                    <BoldText4>Completed Quest</BoldText4>
                    <BoldText13>
                      {questDetails?.completed_quest} (
                      {!isNaN(questDetails?.completed_quest) &&
                      !isNaN(questDetails?.total_quest) &&
                      questDetails?.total_quest > 0
                        ? (
                            (questDetails.completed_quest /
                              questDetails.total_quest) *
                            100
                          ).toFixed(2)
                        : '0.00'}
                      % )
                    </BoldText13>
                  </Col>
                </Row>
              </Styles.DetailItem>

              <Styles.DetailItem>
                <Row>
                  <QuestIcon className='details-icon' />

                  <Col>
                    <BoldText4>In-progress Quest</BoldText4>
                    <BoldText13>
                      <BoldText13>{questDetails?.in_progress_quest}</BoldText13>
                    </BoldText13>
                  </Col>
                </Row>
              </Styles.DetailItem>

              <Styles.DetailItem>
                <Row>
                  <PointCollected className='details-icon' />
                  <Col>
                    <BoldText4>Point Collected</BoldText4>
                    <BoldText13>
                      {questDetails?.points_collected} XS{' '}
                    </BoldText13>
                  </Col>
                </Row>
              </Styles.DetailItem>
            </Row>
          </Col>
        </DetailsContainer>
        <Styles.Wrapper>
          <Styles.Container>
            <Styles.BorderedContainer>
              <Styles.HeadingContainer>
                <Row width='100%' maxWidth='431px'>
                  <SearchBar
                    onChange={(e) => {
                      setSearch(e.target.value)
                      setPage(1)
                      debouncedSearchHandler(e)
                    }}
                    value={search}
                    placeholder='Search...'
                  />
                </Row>

                <Row align='center' gap='18px' className='customize-table'>
                  <Row
                    gap='6px'
                    align='center'
                    onClick={() => {
                      setShowCustomizeColumns((prev) => !prev)
                    }}
                  >
                    <GridIcon />
                    <BoldText4>Customize Table</BoldText4>
                  </Row>
                  <ExportQuestReport
                    downloadFilters={{
                      fields: tableColumn?.map((ele) => ele?.dataKey),
                      user_id: id,
                      ...filtersOptions,
                    }}
                  />
                </Row>
              </Styles.HeadingContainer>
              <div style={{ minHeight: '100px' }}>
                {loader && <Spinner />} {renderTable}
              </div>
            </Styles.BorderedContainer>
            <CustomModal
              show={showCustomizeColumns}
              toggleModal={() => setShowCustomizeColumns((prev) => !prev)}
              heading={<Heading4>Customize Columns</Heading4>}
              showCloseButton
            >
              <CustomColumnModal
                columns={tableHead}
                setTableHead={setTableHead}
                tableColumns={tableColumns}
                setTableColumn={setTableColumn}
                setTableColumns={setTableColumns}
                handleClose={() => setShowCustomizeColumns((prev) => !prev)}
              />
            </CustomModal>
            <CustomModal
              show={showModal}
              toggleModal={() => setShowModal((prev) => !prev)}
              showCloseButton
            ></CustomModal>
          </Styles.Container>
        </Styles.Wrapper>
      </ContentContainer>
    </PageWrapper>
  )
}
