import { Row } from 'shared/styled'
import Tooltip from 'shared/tooltip/Tooltip'
import {
  BoldText13,
  BoldText4,
  BoldText5,
  BolderText4,
} from 'shared/typography'
import styled, { withTheme } from 'styled-components'
import { IGenericNameSlug } from 'utils/type'
type CategoryTextStyle = 'table' | 'detailsPage' | 'top-games'

interface IRenderGameCategoryProps {
  categories: IGenericNameSlug[]
  total?: number
  className?: string
  textStyle?: CategoryTextStyle
  theme?: any
}

export const RenderGameCategory = withTheme(
  (props: IRenderGameCategoryProps) => {
    const { categories = [], theme, total, className, textStyle } = props

    // Create a string of category names starting from the third one
    const remainingCategories = categories
      ?.slice(total ?? 2)
      .map((category) => category.name)
      .join(', ')

    const renderCategories = (
      item: IGenericNameSlug,
      index: number,
      textStyle?: CategoryTextStyle
    ) => {
      switch (textStyle) {
        case 'detailsPage':
          return (
            <BoldText13 key={item?.slug} textWrap='nowrap'>
              {item?.name}
              {index !== categories?.slice(0, 2).length - 1 && ', '}
            </BoldText13>
          )

        case 'top-games':
          return (
            <BolderText4 key={item?.slug} textWrap='nowrap'>
              {item?.name}
              {index !== categories?.slice(0, 2).length - 1 && ', '}
            </BolderText4>
          )

        default:
          return (
            <BoldText5 key={item?.slug} textWrap='nowrap'>
              {item?.name}
              {index !== categories?.slice(0, 2).length - 1 && ', '}
            </BoldText5>
          )
      }
    }

    return (
      <MainContainer className={className} gap='3px' align='center'>
        {categories
          ?.slice(0, total ?? 2)
          .map((item: IGenericNameSlug, index: number) =>
            renderCategories(item, index, textStyle)
          )}

        {categories?.length > (total ?? 2) && (
          <Tooltip text={remainingCategories}>
            <CountContainer>
              <BoldText4 color={theme.primary}>
                +{categories?.length - (total ?? 2)}
              </BoldText4>
            </CountContainer>
          </Tooltip>
        )}
      </MainContainer>
    )
  }
)

const CountContainer = styled.div`
  background-color: ${(props) => props.theme.inputBoxShadow};
  display: inline-block;
  padding: 2px;
  border-radius: 4px;
  margin-left: 5px;
  padding: 2px 4px;
`
const MainContainer = styled(Row)`
  font-weight: 500;
  font-size: 14px;
  font-family: Poppins;
`
