import styled from 'styled-components'
import { Col, Row } from 'shared/styled'

interface IDropdownProps {
  showOptions?: boolean
  isActive?: boolean
  isClicked?: boolean
  hideIcon?: boolean
  overFlow?: string
  width?: string
  disabled?: boolean
}
interface IDropdownList {
  enabled?: boolean
}

export const DropdownContainer = styled(Row)`
  position: relative;
  height: 38px;
  width: 100%;
  max-width: 182px;
  align-items: center;
  gap: 8px;

  .switchIcon {
    position: absolute;
    height: 20px;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .search-input {
    border: none;
    outline: none;
  }

  .input-wrap {
    padding-left: 6px;
  }

  .closeFilters {
    cursor: pointer;
  }

  .applyButton {
    height: 28px;
  }
  .customOption {
    color: ${(props) => props.theme.primary};
  }

  &.dateDropdown {
    min-width: 150px;
  }

  &.SaleTypeDropdown {
    min-width: 130px;
  }

  .dropdown > .dropdownHeader {
    border-radius: 50px;
    min-width: 180px;
  }
  .dropdownHeader {
    font-size: 12px;
    color: rgb(41, 45, 50);
    line-height: 3.4;
  }
`

export const DropdownContainer2 = styled(DropdownContainer)`
  max-width: unset;
`

export const DropdownHeader = styled.div<IDropdownProps>`
  all: unset;
  border-radius: 50px;
  color: ${(props) => props.theme.icon};
  line-height: 38px;
  padding-inline: 12px;
  background: ${(props) =>
    props.disabled ? props.theme.borderColor : props.theme.secondary};
  border: 1px solid ${(props) => props.theme.borderColor};
  /* width: 204px; */
  width: 100%;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  gap: 10px;
  & > div {
    color: ${(props) => props.theme.icon};
    line-height: 38px;

    background: ${(props) => props.theme.secondary};
    /* width: 204px; */
    width: 100%;

    cursor: pointer;
    align-items: center;
    white-space: nowrap;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    gap: 10px;
  }
  .arrowDownIcon {
    height: 18px;
    width: 18px;
    transition: rotate 0.5s;
    rotate: ${(props) => !props.showOptions && `-180deg`};
  }
`
export const DropdownHeader2 = styled(DropdownHeader)`
  max-width: unset;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.disabled && props.theme.borderColor};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

export const DropdownContent = styled.div<IDropdownProps>`
  position: absolute;
  top: calc(100% + 8px);
  right: 0px;
  background: ${(props) => props.theme.secondary};
  border: ${(props) =>
    !props.showOptions ? 'none' : `1px solid ${props.theme.borderColor}`};
  width: ${(props) => props.width ?? '100%'};
  max-width: ${(props) => (props.hideIcon ? 'unset' : '176px')};
  border-radius: 6px;
  z-index: 3;
  width: 100%;
  transition: max-height 0.5s cubic-bezier(1, 0, 0, 1);
  max-height: ${(props) => (props.showOptions ? '300px' : '0px')};
  overflow: ${(props) => props.overFlow ?? 'hidden'};
`

export const DropdownContent2 = styled(DropdownContent)`
  max-width: unset;
  border: ${(props) =>
    !props.showOptions ? 'none' : `1px solid ${props.theme.borderColor}`};
  z-index: 10;
`

export const CheckBoxDropdownContent = styled(DropdownContent)`
  display: ${(props) => (props.showOptions ? 'flex' : 'none')};
  flex-direction: column;
  max-width: unset;
  border: ${(props) =>
    !props.showOptions ? 'none' : `1px solid ${props.theme.borderColor}`};
  max-height: 250px;
  overflow-y: auto;
`

export const DropdownListItem = styled.div<IDropdownList>`
  display: flex;
  padding: 8px 12px;
  cursor: ${(props) => (props?.enabled ? 'pointer' : 'not-allowed')};
  background-color: ${(props) =>
    props?.enabled ? 'transparent' : props.theme.borderColor};
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  color: ${(props) => props.theme.primaryText};

  & > div {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    color: ${(props) => props.theme.primaryText};
  }

  :hover {
    & > div {
      color: ${(props) => props.theme.secondary};
    }
    color: ${(props) => props.theme.secondary};
    background-color: ${(props) => props.theme.primary};
  }
`

export const DropdownListContainer = styled(Col)`
  width: 100%;
  list-style-type: none;
  padding: 0;
  max-height: 350px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.secondary};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.borderColor};
`

interface IDropdownProps {
  isDisabled?: boolean
  showOptions?: boolean
}

export const CheckboxDropdownListItem = styled.div<IDropdownProps>`
  display: flex;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  color: ${(props) => props.theme.primaryText};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : '')};
  background: ${(props) =>
    props.isDisabled ? props.theme.borderColor : 'transparent'};

  & > div {
    font-size: 14px;
    font-weight: 400;
    text-align: start;
    color: ${(props) => props.theme.primaryText};
}
  }

  :hover {
    & > label p {
      color: ${(props) => props.theme.secondary};
    }
    background-color: ${(props) => props.theme.primary};
  }
`

export const IconContainer = styled.img`
  height: 16px;
  width: 16px;
  object-fit: contain;
`
