import { createReducer } from '@reduxjs/toolkit'
import { IDownloadReportApiState } from 'utils/type'
import { setDownloadReportApi } from '../actions/downloadReport'

const initialState: IDownloadReportApiState = {
  downloadReportApi: null,
  downloadReportFilters: null,
  downloadReportFileName: '',
  processedList: null,
  tableHeadersList: null,
}

const downloadReportReducer = createReducer(initialState, (builder) => {
  builder.addCase(setDownloadReportApi, (state, action) => {
    state.downloadReportApi = action.payload[0]
    state.downloadReportFilters = action.payload[1]
    state.downloadReportFileName = action.payload[2]
    state.processedList = action.payload[3]
    state.tableHeadersList = action.payload[4]
  })
})

export default downloadReportReducer
