import styled from 'styled-components'
import { filterContainerWidth } from './Filters/constants'
import { screenSizes } from 'styles/theme'
import { Row } from './styled'

export const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 28px 0 95px 36px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
`

export const FilterBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  :hover {
    cursor: pointer;
  }
`
export const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const ClearBtn = styled.div`
  display: flex;
  align-items: center;
  :hover {
    padding-left: 10px;
    transform: scale(1.1);
    transition: all 0.3s ease-in;
    cursor: pointer;
  }
`

export const AppliedFilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  border: 1px solid rgba(41, 45, 50, 0.2);
  border-radius: 10px;
  padding: 12px 10px;

  .clearFilterIcon {
    height: 12px;
    width: 12px;
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.primaryText};
    }
  }
`

export const FiltersContainer = styled.div`
  display: flex;
  overflow: scroll;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: ${filterContainerWidth};
  flex-direction: column;
  position: absolute;
  top: 0;
  right: -36px;
  z-index: 9999;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  gap: 21px;
  .closeFilters {
    :hover {
      cursor: pointer;
      transform: scale(1.15) rotate(90deg);
      transition: transform 0.3s ease-in;
    }
  }
`

export const AppliedFiltersContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

  .clearFilterIcon {
    height: 12px;
    width: 12px;
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.primaryText};
    }
  }

  @media (min-width: ${screenSizes.mediaS}px) {
    grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  }
`

export const FiltersWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 29px 0 32px;
`
export const TabRow = styled(Row)`
  border-bottom: 1px solid ${(props) => props.theme.cardShadow};
`
