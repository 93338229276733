export const SUCCESS_LOTTIE_PATH =
  'https://assets9.lottiefiles.com/packages/lf20_sabvcsrv.json'
export const MSG_SENT_LOTTIE_PATH =
  'https://lottie.host/e59f47c8-8c78-4ad4-bb0d-d335381218ee/7XCTxvUmwj.json'

export const ACTIVATE_CATEGORY_TEXT =
  'Activating the category that enable Players to buy, sell, and trade digital trading cards in the NFT gaming platform, as displayed in the admin dashboard'
export const DEACTIVATE_CATEGORY_TEXT =
  'Deactivate this category to disable Players from buying, selling, and trading digital trading cards in the NFT gaming platform, as reflected in the admin dashboard.'
export const DELETE_CATEGORY_TEXT =
  'Deleting category to permanently remove the option for Players to buy, sell, and trade digital trading cards in the NFT gaming platform, as reflected in the admin dashboard.'

export const DISABLE_NOTIFICATION_TEXT =
  "Players will no longer receive notifications of this type from the platform. This change will take effect immediately and will be reflected in the user's notification settings."
export const ENABLE_NOTIFICATION_TEXT =
  "Players will start receiving notifications of this type from the platform. This change will take effect immediately and will be reflected in the user's notification settings."
export const DELETE_NOTIFICATION_SETTING_TEXT =
  "Players will no longer receive notifications of this type from the platform. This change will take effect immediately and will be reflected in the user's notification settings."

export const DEACTIVATE_ASSET_TEXT =
  'Are you sure you want to deactivate this NFT on your platform? Once deactivated, it will no longer be visible to Players and will not be available for purchase. Please confirm your action.'
export const ACTIVATE_ASSET_TEXT =
  'Are you sure you want to activate this NFT on your platform? Once activated, it will be visible to Players and available for purchase. Please confirm your action.'

export const ACTIVATE_GAME_TEXT =
  'Are you sure you want to activate this game on your platform? Once activated, it will be visible to Players and available for purchase. Please confirm your action.'
export const DEACTIVATE_GAME_TEXT =
  'Are you sure you want to deactivate this game on your platform? Once deactivated, it will no longer be visible to Players and will not be available for purchase. Please confirm your action.'
export const ACTIVATE_STARTER_PACK_TEXT =
  'Are you sure you want to activate this starter pack on your platform? Once activated, it will be visible to Players and available for purchase. Please confirm your action.'
export const DEACTIVATE_STARTER_PACK_TEXT =
  'Are you sure you want to deactivate this starter pack on your platform? Once deactivated, it will no longer be visible to Players and will not be available for purchase. Please confirm your action.'

export const DEACTIVATE_FEATURED_GAME_TEXT =
  'Are you sure you want to remove this game from featured game on your platform? Once deactivated, it will no longer be visible to Players on featured. Please confirm your action.'

export const DEACTIVATE_TOP_GAME_TEXT =
  'Are you sure you want to remove this game from top game from your platform? Once removed, it will no longer be visible to Players on top. Please confirm your action.'

export const ACTIVATE_SUB_ADMIN_TEXT =
  'Enabling the sub-admin restores their access to the admin panel, allowing them to resume performing actions and responsibilities'

export const DEACTIVATE_SUB_ADMIN_TEXT =
  'Disabling the sub-admin restricts access to the admin panel and prevents any further actions'
export const ACTIVATE_SOCIAL_MEDIA_TEXT =
  'Enabling this social media option will allow users to view it on their dashboard and connect with it.'

export const DEACTIVATE_QUEST_TEXT =
  ' Are you sure you want to deactivate this Quest on your platform? Once deactivated, it will no longer be visible to Players and will not be available for participate. Please confirm your action.'
export const ACTIVATE_QUEST_TEXT =
  'Are you sure you want to activate this Quest on your platform? Once activated, it will be visible to Players and available for participate. Please confirm your action.'
export const DEACTIVATE_QUEST_CATEGORY_TEXT =
  'Are you sure you want to deactivate this category on your platform? Once deactivated, it will no longer be visible to Players and will not be available for participate. Please confirm your action.'
export const ACTIVATE_QUEST_CATEGORY_TEXT =
  'Are you sure you want to activate this Category on your platform? Once activated, it will be visible to Players and available for participate. Please confirm your action.'
export const DEACTIVATE_QUEST_EVENT_TEXT =
  'Are you sure you want to deactivate this Event on your platform? Once deactivated, it will no longer be visible to Players and will not be available for participate. Please confirm your action.'
export const ACTIVATE_QUEST_EVENT_TEXT =
  'Are you sure you want to activate this Event on your platform? Once activated, it will be visible to Players and available for participate. Please confirm your action.'
export const DEACTIVATE_SOCIAL_MEDIA_TEXT =
  'Disabling this social media option will restrict users from viewing it on their dashboard and connecting with it.'
export const ACTIVATE_SOCIAL_SHARE_TEXT =
  'Enabling this social share option will allow users to share assets'

export const DEACTIVATE_SOCIAL_SHARE_TEXT =
  'Disabling this social share option will restrict users to share assets'

export const TRANSACTION_COUNT_VALIDATION_TEXT =
  'Min transaction count should not be greater than max transaction count'

export const ACTIVATE_ASSET_VISIBILITY_TEXT =
  'Switch to see only game-related stuff, making it easier to find and manage what matters most to players in one dedicated space.'

export const DEACTIVATE_ASSET_VISIBILITY_TEXT =
  'Disabling the game asset view ensures that players can explore all types of assets on the platform, preventing any specific focus on game-related content. This not only eliminates potential confusion and misinformation but also promotes a transparent and genuine experience for players.'

export const PRICE_RANGE_VALIDATION_TEXT =
  'Min price should not be greater than max price'

export const DEACTIVATE_MARKETPLACE_TEXT =
  'Are you sure to deactivate the marketplace?'
export const ACTIVATE_MARKETPLACE_TEXT =
  'Are you sure to activate the marketplace?'

export const REMOVE_MARKETPLACE_TEXT =
  'Are you sure you want to remove this marketplace type from your platform?'

export const REMOVE_INTEGRATION_TEXT =
  'Are you sure you want to remove this integration?'

export const DEACTIVATE_USER_TYPE_TEXT =
  'Are you sure to deactivate the user type?'
export const ACTIVATE_USER_TYPE_TEXT = 'Are you sure to activate the user type?'

export const DEACTIVATE_WEB_CAROUSEL_PAGE_TEXT =
  'Are you sure you want to deactivate this slider on your platform? Once deactivated, it will no longer be visible to Players. Please confirm your action.'
export const ACTIVATE_WEB_CAROUSEL_PAGE_TEXT =
  'Are you sure you want to activate this slider on your platform? Once activated, it will be visible to Players. Please confirm your action.'

export const ACTIVATE_BANNER_TEXT =
  'Are you sure you want to activate this slider on your platform? Once activated, it will be visible to Players'

export const DEACTIVATE_BANNER_TEXT =
  'Are you sure you want to deactivate this slider on your platform? Once deactivated, it will no longer be visible to Players'

export const REMOVE_USER_TYPE_TEXT =
  'Are you sure you want to remove this user type from your platform?'

export const playerBlockReasonsOption = [
  { label: 'Violation of community guidelines or terms of service', type: '1' },
  { label: 'Spamming or engaging in fraudulent activities', type: '2' },
  { label: 'Breaching security or privacy policies', type: '3' },
  {
    label: 'Disruptive behavior or negative impact on user experience',
    type: '4',
  },
  { label: 'Repeated violations despite warnings or sanctions', type: '5' },
  { label: 'Other', type: '6' },
]

export const UnblockReasonsOption = [
  {
    label: 'Issue Resolved',
    type: '1',
  },
  {
    label: 'Appeal Approved',
    type: '2',
  },
  {
    label: 'Temporary Block Expired',
    type: '3',
  },
  {
    label: 'Reinstated After Review',
    type: '4',
  },
  {
    label: 'First-Time Violation',
    type: '5',
  },
  { label: 'Account Recovery', type: '6' },
  { label: 'Error Correction', type: '7' },
  { label: 'Other', type: '8' },
]

export const BLOCKED_PLAYERS = 'blockedPlayers'
export const BANNED_PLAYERS = 'bannedPlayers'
export const UNBLOCKED_PLAYERS = 'unblockedPlayers'
export const ACCOUNT_DELETE_REQUEST = 'accountDeleteRequest'

export const REQUESTED_LAUNCHPADS = 'Requested'
export const LISTED_LAUNCHPADS = 'Listings'
export const VERIFIED_LAUNCHPADS = 'Verified'
export const REJECTED_LAUNCHPADS = 'Rejected'

export const ACTIVE_PUBLISHERS = 'ACTIVE'

export const REQUEST_PUBLISHERS = 'REQUEST'
export const BLOCKED_PUBLISHERS = 'BLOCKED'
export const REJECTED_PUBLISHERS = 'REJECTED'
export const COMPANY_REQUEST = 'COMPANY_REQUEST'

export const TRANSFER = 'TRANSFER'
export const OFFER = 'OFFER'
export const SALE = 'SALE'
export const LIST = 'LIST'
export const CANCEL = 'CANCEL'
export const MINT = 'MINT'
export const AUCTION = 'AUCTION'

export const ACTIVITY = 'ACTIVITY'
export const ASSETS = 'ASSETS'
export const GAMES = 'GAMES'
export const LAUNCHPAD = 'LAUNCHPAD'
export const SUBSCRIPTION = 'SUBSCRIPTION'

export const CATEGORIES = 'categories'
export const STATIC_CONTENT = 'static-content'
export const MANAGE_FEATURED = 'manage-featured'
export const MANAGE_TOP_GAMES = 'manage-top-games'
export const WALLET = 'wallet'
export const STARTER_PACK = 'starter-pack'
export const GENERAL_SETTINGS = 'general-settings'
export const MARKETPLACE_TYPE = 'marketplace-type'
export const USER_TYPE = 'user-type'
export const BANNERS = 'banners'
export const INTEGRATION = 'integration'
export const REPORTS = 'reports'
export const CUSTOMIZE_PLAN = 'customize-plan'

export const SECURITY_QUESTIONS_TAB = 'security-questions'
export const FAQ_TAB = 'faq'
export const BLOGS_TAB = 'blogs'
export const EDUCATION_TAB = 'education'
export const SOCIAL_MEDIA = 'social-media'
export const GENERAL_TAB = 'general'
export const PRIVACY_POLICY_TAB = 'privacy-policy'
export const TERMS_TAB = 'terms'
export const CONTACT_US_TAB = 'contact-us'
export const GLOSSARY_TAB = 'glossary'
export const ACCOUNT_DELETION = 'account-deletion'

export const ACTIVE_GAMES = 'activeGames'
export const INACTIVE_GAMES = 'inactiveGames'
export const GAME_REQUESTS = 'gameRequests'

export const QUEST_TAB = 'quest'
export const EVENTS_TAB = 'events'
export const CATEGORIES_TAB = 'categories'
export const LOGIC_GROUP_TAB = 'logic_group'
export const LOGIC_GROUP_REQUEST_TAB = 'logic_group-request'
export const QUEST_REQUEST_TAB = 'quest-request'

export const QUEST_DETAILS_STATS = 'stats'
export const QUEST_DETAILS_MISSIONS = 'missions'
export const QUEST_DETAILS_PARTICIPANTS = 'participants'
export const QUEST_DETAILS_REWARDS = 'rewards'

export const QUEST_DETAILS_REWARDS_TAB = 'rewards'
export const QUEST_DETAILS_REWARDS_CLAIMED = 'claimed'

export const QUEST_REQUEST_DETAILS_MISSIONS = 'missions'
export const QUEST_REQUEST_DETAILS_REWARDS = 'rewards'

export const QUEST_EVENT_DETAILS_QUESTS = 'quest'
export const QUEST_EVENT_DETAILS_USER_ACTIVITY = 'user-activity'

export const WEB_CAROUSEL_TAB = 'web-carousel'
export const WEB_CAROUSEL_REQUEST_TAB = 'web-carousel-request'
