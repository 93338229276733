import { withTheme } from 'styled-components'
import { AnimatedLoader2 } from './style'

export const Loader2 = withTheme((props) => {
  const { testid } = props

  return (
    <AnimatedLoader2 data-testid={testid}>
      <div className='lds-ellipsis'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </AnimatedLoader2>
  )
})
