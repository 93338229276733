import adminReducer from './admin.reducer'
import loginReducer from './login.reducer'
import priceReducer from './price.reducer'
import questReducer from './quest.reducer'
import webCarouselReducer from './webCarousel.reducer'
import userReportReducer from './userReport.reducer'
import userReportFilterReducer from './userReportFilters.reducer'
import activityReportReducer from './activityReport.reducer'
import activityReportFilterReducer from './activityReportFilters.reducer'
import downloadReportReducer from './downloadReport.reducer'
import downloadActivityReportReducer from './downloadActivityReport.reducer'

import { IRootReducer } from 'utils/type'

const rootReducer: IRootReducer = {
  loginReducer,
  adminReducer,
  priceReducer,
  questReducer,
  downloadReportReducer,
  webCarouselReducer,
  userReportReducer,
  userReportFilterReducer,
  activityReportReducer,
  activityReportFilterReducer,
  downloadActivityReportReducer,
}

export default rootReducer
