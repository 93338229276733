import { FC, useEffect, useRef } from 'react'

import closeIcon from 'assets/icons/cross.svg'
import { Close, ModalBody, ModalContent, ModelHead } from './style'

interface IModalProps {
  show: boolean
  toggleModal?: Function
  borderRadius?: string
  heading?: React.ReactNode
  styles?: any
  headingStyle?: any
  showCloseButton?: boolean
  disableClose?: boolean
  customBg?: string
  bodyStyles?: any
  children?: React.ReactNode
  className?: string
}

const CustomModal: FC<IModalProps> = (props) => {
  const {
    show,
    toggleModal,
    borderRadius,
    heading,
    styles,
    headingStyle,
    showCloseButton,
    disableClose = false,
    customBg,
    bodyStyles,
    className,
  } = props

  const savedScrollPosition = useRef(0)

  const handleClickOutside = (e: any) => {
    if (e.target === e.currentTarget && !disableClose && toggleModal) {
      toggleModal()
    }
  }

  useEffect(() => {
    // Save the current scroll position when the modal is opened
    if (show) {
      savedScrollPosition.current = window?.scrollY
      document.body.style.overflow = 'hidden'
    } else {
      // Restore the scroll position and enable scrolling when the modal is closed
      document.body.style.overflow = 'unset'
      window?.scrollTo(0, savedScrollPosition.current)
    }

    // Clean up the saved scroll position when the component unmounts
    return () => {
      document.body.style.overflow = 'unset'
      if (window) {
        window.scrollTo(0, savedScrollPosition.current)
      }
    }
  }, [show])

  return (
    <ModalBody
      show={show}
      customBg={customBg}
      onClick={handleClickOutside}
      bodyStyles={bodyStyles}
      data-testid='modal'
    >
      <ModalContent
        onClick={(e: any) => {
          e.stopPropagation()
        }}
        borderRadius={borderRadius}
        style={{ ...styles }}
        className={className}
      >
        <ModelHead style={{ ...headingStyle }}>
          {heading}
          {showCloseButton && !disableClose && (
            <Close
              onClick={() => toggleModal?.(!show)}
              src={closeIcon}
              data-testid='modal-close-button'
            />
          )}
        </ModelHead>
        {props.children}
      </ModalContent>
    </ModalBody>
  )
}
export default CustomModal
