import styled from 'styled-components'
import { Row, Col } from './styled'

export const ReportTableContainer = styled.div`
  margin: 5px;
  width: calc(100% - 10px);
  overflow: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  // border-radius: 6px;

  .viewDetailsText {
    cursor: pointer;
  }

  .starIcon {
    height: 24px;
    width: 24px;

    :active {
      scale: 0.9;
    }
  }

  table {
    border-collapse: separate;
    width: 100%;
  }
  .collection {
    font-weight: 500;
    font-size: 14px;
    font-family: Poppins;
  }
  thead {
    .boldHeader {
      color: ${(props) => props.theme.tableRowText};
      font-family: Poppins;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
    }

    tr {
      border-radius: 5px;
      border: 1px solid rgb(0 0 0 / 10%);
    }
    th {
      padding: 14px 10px;
      background: rgba(231, 231, 231, 0.4);
      border: 1px solid ${(props) => props.theme.borderColor};
      border-right: 1px solid ${(props) => props.theme.secondary};
    }
  }

  tbody tr:last-child {
    border: none;
  }
  tbody {
    tr {
      border-bottom: 1px solid ${(props) => props.theme.borderColor};
      transition: 0.2s linear;

      :hover {
        background-color: #e7e7e7;
      }
    }
    td {
      padding: 10px;
      height: 50px;
    }
  }
`

export const SaveContainer = styled(Col)`
  label {
    color: ${(props) => props.theme.tableRowText};
  }
`

export const DropdownContainer = styled(Row)`
  .filterDropdown {
    height: 44px;
    font-size: 14px;
    font-weight: 500;
    color: gray;
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }
`

export const CustomReportInput = styled.input`
  width: 100%;
  max-width: 600px;
  height: 44px;
  padding: 10px;
  background-color: transparent;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 4px;
  flex-shrink: 0;
  outline: none;

  &:focus {
    border: 1px solid ${(props) => props.theme.primary};
  }
`
