import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { serverConfig } from './constants'
import { getLocalJwt } from './helpers/storageHelpers'
const baseURL = serverConfig.baseURL

const config: AxiosRequestConfig = {
  baseURL: `${baseURL}/`,
}

const axiosClient: AxiosInstance = axios.create(config)

axiosClient.interceptors.request.use(
  (config) => {
    const token = getLocalJwt()
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    if (!(error instanceof Error)) {
      error = new Error(error)
    }
    return Promise.reject(error)
  }
)

export { axiosClient }
