import DropDownArrow from 'assets/icons/arrow-swap-down.svg'
import { ReactComponent as DocumentDownloadIcon } from 'assets/icons/document-download.svg'
import { setDownloadReportApi } from 'logic/redux/actions/downloadReport'
import { useAppDispatch } from 'logic/redux/hooks'
import moment from 'moment'
import { useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { Button } from 'shared/button'
import { useClickOutside } from 'shared/hooks/useClickOutSide'
import { Row } from 'shared/styled'
import { BoldText4 } from 'shared/typography'
import { DownloadQuestReport } from 'utils/apis/quests/quest'
import { REPORT_EXPORT_FILE_EXTENSION } from 'utils/enums'
import { capitalizeFirstLetter } from 'utils/helpers/stringHelpers'
import { Container, OptionContainer, OptionHeader, Wrapper } from './style'
import { IQuestReportFilters } from 'utils/type'
const reportOptions = [
  {
    label: capitalizeFirstLetter(REPORT_EXPORT_FILE_EXTENSION.CSV),
    value: REPORT_EXPORT_FILE_EXTENSION.CSV,
  },
  {
    label: capitalizeFirstLetter(REPORT_EXPORT_FILE_EXTENSION.XLS),
    value: REPORT_EXPORT_FILE_EXTENSION.XLS,
  },
]

interface IExportReportProps {
  downloadFilters: IQuestReportFilters
}

export const ExportQuestReport = (props: IExportReportProps) => {
  const { downloadFilters } = props
  const { page, limit, ...rest } = downloadFilters

  const dispatch = useAppDispatch()
  const ref = useRef<HTMLElement>()

  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [exportFormat, setExportFormat] = useState(
    REPORT_EXPORT_FILE_EXTENSION.CSV
  )

  useClickOutside(ref, setShowOptions)

  return (
    <Wrapper>
      <Container showOptions={showOptions}>
        <OptionHeader
          onClick={() => setShowOptions((prev) => !prev)}
          ref={(el: HTMLDivElement) => {
            if (!el) return
            ref.current = el
          }}
        >
          <Row align='center'>
            <BoldText4 width='30px'>
              {capitalizeFirstLetter(exportFormat)}
            </BoldText4>
            <img
              className='arrowDownIcon'
              src={DropDownArrow}
              alt='arrow-icon'
            />
          </Row>

          {showOptions ? (
            <OptionContainer>
              {reportOptions?.map((option) => (
                <BoldText4
                  key={option.value}
                  onClick={() => setExportFormat(option.value)}
                >
                  {option.label}
                </BoldText4>
              ))}
            </OptionContainer>
          ) : null}
        </OptionHeader>
      </Container>
      <Button
        btnType='secondary'
        disableCircularLoadAnimation
        icon={<DocumentDownloadIcon className='documentIcon' />}
        onClick={() => {
          dispatch(
            setDownloadReportApi(
              DownloadQuestReport,
              { file_type: exportFormat, ...rest },
              `QuestReport_${moment().format(
                'YYYY-MM-DD_h-mma'
              )}.${exportFormat?.toLowerCase()}`,
              [],
              []
            )
          )
          toast.success('Export started. Your report will be generated soon.')
        }}
      >
        Export Report
      </Button>
    </Wrapper>
  )
}
