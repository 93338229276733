import { ls } from 'dev/secureLS'

export const getLocalJwt = () => {
  return ls.get('userAccessToken') ?? sessionStorage.getItem('userAccessToken')
}

export const clearStorage = () => {
  const isRememberMe = ls.get('isChecked') === 'true'
  const localStorageKeys = Object.keys(localStorage)

  sessionStorage.clear()

  if (isRememberMe) {
    // if remember me is checked skip isChecked and email and remove rest
    const skipKeys = ['isChecked', 'email']
    localStorageKeys.forEach((key) => {
      if (skipKeys.includes(key)) {
        return
      } else {
        ls.remove(key)
      }
    })
  } else {
    localStorage.clear()
  }
}
