import React from 'react'
import { Row } from 'shared/styled'
import styled from 'styled-components'

interface TooltipProps {
  text: string
  children?: React.ReactNode
  Icon?: any
  direction?: 'top' | 'bottom' | 'left' | 'right'
}

/**
 * Tooltip component that displays a tooltip text when hovered.
 *
 * @component
 * @example
 * <Tooltip text="This is a tooltip">Hover me</Tooltip>
 *
 * @param {string} text - The text to display in the tooltip.
 * @param {React.ReactNode} [children] - The content to display inside the tooltip.
 * @param {any} [Icon] - An optional icon to display alongside the tooltip content.
 * @param {'top' | 'bottom' | 'left' | 'right'} [direction] - The direction in which the tooltip should appear.
 *   Defaults to 'top'.
 * @returns {React.FC<TooltipProps>} The Tooltip component.
 */

const Tooltip: React.FC<TooltipProps> = ({
  text,
  children,
  Icon,
  direction,
}) => {
  return (
    <TooltipContainer>
      {children}
      {Icon}
      <TooltipText direction={direction}>{text}</TooltipText>
    </TooltipContainer>
  )
}

export default Tooltip

const TooltipContainer = styled(Row)`
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: relative;
  cursor: pointer;
  text-align: center;
`

const TooltipText = styled.span<{ direction?: string }>`
  visibility: hidden;
  width: max-content;
  max-width: 220px;
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.primaryText2};
  text-align: center;
  border: 1px solid ${(props) => props.theme.secondary};
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;

  box-shadow: 0px 3px 13px 0px rgba(145, 99, 204, 0.13);

  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${(props) => {
    switch (props.direction) {
      case 'bottom':
        return `
          left: 50%;
          bottom: -280%;
          transform: translateX(-50%);
        `
      case 'left':
        return `
          right: 150%;
          top: 50%;
          transform: translateY(-50%);
        `
      case 'right':
        return `
          left: 150%;
          top: 50%;
          transform: translateY(-50%);
        `
      default:
        // top by default
        return `
          left: 50%;
          bottom: 150%;
          transform: translateX(-50%);
          margin:0;
        `
    }
  }}

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }
`
