import axios from 'axios'
import { handleError } from 'utils/apis'
import { axiosClient } from 'utils/axios'
import { serverConfig } from 'utils/constants'
import { IGamesListFilters } from 'utils/type'

export const GetDashboardCharts = async (
  name: string,
  time: string,
  chainType?: string
) => {
  try {
    const url = serverConfig.baseURL + serverConfig.dashboardCharts
    const res = await axiosClient.get(
      `${url}?name=${name}&chart_type=${time}&chain_type=${chainType}`
    )
    return res?.data
  } catch (error: any) {
    console.error('error..', error)
  }
}
export const GetDashboardstats = async (chainType: string) => {
  try {
    const url = serverConfig.baseURL + serverConfig.dashboardStats
    const res = await axiosClient.get(`${url}?chain_type=${chainType}`)
    return res?.data
  } catch (error: any) {
    console.error('error..', error)
  }
}

export const GetTransactionLog = async (
  filters?: Partial<IGamesListFilters>
) => {
  const url = serverConfig.baseURL + serverConfig.getTransactionLogs
  try {
    const res = await axiosClient.post(url, filters)
    const { data: usersList, total, total_page } = res.data
    return { count: total, result: usersList, total_page }
  } catch (error) {
    handleError(error)
    return {}
  }
}
export const GetTotalSalesList = async (
  filters?: Partial<IGamesListFilters>
) => {
  const url = serverConfig.baseURL + serverConfig.totalSales
  try {
    const res = await axiosClient.post(url, filters)
    const { data: usersList, total, total_page } = res.data
    return { count: total, result: usersList, total_page }
  } catch (error) {
    handleError(error)
    return {}
  }
}
export const GetActiveUsersList = async (
  filters?: Partial<IGamesListFilters>
) => {
  const url = serverConfig.baseURL + serverConfig.activeUsers

  try {
    const res = await axiosClient.post(url, filters)
    const { data: usersList, total } = res.data

    return { count: total, result: usersList }
  } catch (error) {
    handleError(error)
    return {}
  }
}

export const DownloadTransactionLogData = async (
  filters?: Partial<IGamesListFilters>
) => {
  const url = serverConfig.baseURL + serverConfig.downloadTransactionLogs
  try {
    const res = await axiosClient.post(url, filters)
    return res.data
  } catch (error) {
    console.error(error)
    return {}
  }
}
export const DownloadActiveUsersData = async (
  filters?: Partial<IGamesListFilters>
) => {
  const url = serverConfig.baseURL + serverConfig.downloadActiveUsers
  try {
    const res = await axiosClient.post(url, filters)
    const { data: usersList, total } = res.data

    return { count: total, result: usersList }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const DownloadTotalSalesData = async (
  filters?: Partial<IGamesListFilters>
) => {
  const url = serverConfig.baseURL + serverConfig.downloadTotalSales
  try {
    const res = await axiosClient.post(url, filters)
    const { data: usersList, total } = res.data

    return { count: total, result: usersList }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const DownloadActivePublishersData = async (
  filters?: Partial<IGamesListFilters>
) => {
  const url = serverConfig.baseURL + serverConfig.downloadActivePublishers
  try {
    const res = await axiosClient.post(url, filters)
    const { data: usersList, total } = res.data

    return { count: total, result: usersList }
  } catch (error) {
    console.error(error)
    return {}
  }
}

export const GetAdminProfileData = async (token: string) => {
  try {
    const url = serverConfig.baseURL + serverConfig.profileData
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': 'true',
      },
    })
    return response?.data
  } catch (error) {
    handleError(error)
  }
}
