import styled from 'styled-components'
import { screenSizes } from 'styles/theme'
import { Row } from './styled'

export const DetailsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 10px;
  gap: 24px;
`

export const DetailsTop = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  width: 100%;
  padding-bottom: 20px;
`

export const DetailsRow1 = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  justify-content: space-between;
  gap: 50px;
`
export const DetailsRow2 = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  gap: 50px;
`

export const DetailItem = styled.div`
  display: flex;
  width: 100%;
  max-width: 343px;
  justify-content: start;
`

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
`

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 36px;
`
export const PlayerDetailsContainer = styled.div`
  display: flex;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  flex-direction: column;
`
export const DetailsTopSection = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 36px;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
`
export const DetailsBottomSection = styled.div`
  display: flex;
  padding-top: 36px;
  width: 100%;
`
export const Line = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.borderColor};
  padding-top: 36px;
`
export const DetailsBottomLeft = styled.div`
  display: flex;
  border-right: 1px solid ${(props) => props.theme.borderColor};
  flex-direction: column;
  width: 100%;
  gap: 16px;
  ul {
    padding-left: 25px;
  }
`
export const DetailsBottomRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 36px;
  gap: 2px;
`
export const DetailsBottomRightRow = styled.div`
  display: flex;
  width: 100%;
  padding-left: 36px;
  gap: 10px;
  align-items: center;
`

export const AutoGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
  @media (min-width: ${screenSizes.mediaXCL}px) {
    max-width: 1075px;
  }
`
export const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 246px;
  height: 141px;
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 12px;
  padding: 16px 15px;
  gap: 11px;
`

export const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: space-between;
  gap: 50px;
`

export const TabsContainer = styled(Row)`
  width: 100%;
  padding-top: 2rem;
`

export const BorderedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 28px;
  margin: 1rem 0;
  gap: 20px;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.borderColor};
`

export const ModalSubContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  width: 518px;
`

export const ModalSubTitleContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  p {
    width: 376px;
  }
`

export const AvailableContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${(props) => props.theme.primary};
  border-radius: 6px;
  width: max-content;
  height: 30px;
  padding: 0 10px;
  color: ${(props) => props.theme.primary};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`
