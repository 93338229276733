import { createReducer } from '@reduxjs/toolkit'
import { isLoggedIn } from '../actions/login'
import { ILoginState } from 'utils/type'

const initialState: ILoginState = {
  userLoggedIn: false,
}

const loginReducer = createReducer(initialState, (builder) => {
  builder.addMatcher(
    (action) => isLoggedIn.fulfilled.match(action),
    (state, action) => {
      state.userLoggedIn = action.payload
    }
  )
})

export default loginReducer
