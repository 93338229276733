import { createReducer } from '@reduxjs/toolkit'
import { setQuestTabsCount } from '../actions/questActions'
import { IQuestTabCount } from 'utils/type'

const initialState: IQuestTabCount = {
  event: 0,
  event_category: 0,
  logic_group: 0,
  quest: 0,
  quest_request: 0,
  logic_group_request: 0,
}

const questReducer = createReducer(initialState, (builder) => {
  builder.addCase(setQuestTabsCount, (state, action) => {
    const { key, value } = action.payload // assuming payload contains the key and value to update
    return {
      ...state,
      [key]: value,
    }
  })
})

export default questReducer
