import { withTheme } from 'styled-components'

import { LoadingContainer } from './style'
import { AnimatedLoader } from 'shared/styled'
import { Theme } from 'styles/theme'

interface ISpinner {
  size?: string
  theme?: Theme
}

export const Spinner = withTheme((props: ISpinner) => {
  const { theme, size } = props

  return (
    <LoadingContainer data-testid='spinner'>
      <AnimatedLoader
        size={size}
        loaderColor={theme?.primary}
        data-testid='animated-loader'
      />
    </LoadingContainer>
  )
})
