type UrlPath<T> = string & { _?: T } // Basically a string.  The second clause is to peg the generic type

export const rootPath: UrlPath<{}> = '/'
export const signInPath: UrlPath<{}> = '/sign-in'
export const forgotPasswordPath: UrlPath<{}> = '/forgot-password'
export const verifyOtpPath: UrlPath<{}> = '/verify-otp'
export const resetPasswordPath: UrlPath<{}> = '/reset-password'
export const homePath: UrlPath<{}> = '/home'

export const playersPath: UrlPath<{}> = '/players'
export const playerProfilePath: UrlPath<{}> = '/players/player-profile/:id'
export const playerProfilePathGeneric: UrlPath<{}> = '/players/player-profile'

export const gamePublisherPath: UrlPath<{}> = '/publisher'
export const publisherProfilePath: UrlPath<{}> =
  '/publisher/publisher-profile/:id'
export const publisherProfilePathGeneric: UrlPath<{}> =
  '/publisher/publisher-profile'
export const publisherCompanyDetailsPath: UrlPath<{}> = '/company-request/:id'

export const companyRequestChangesPath: UrlPath<{}> = `/company-request/view-changes/:id`

export const activityPath: UrlPath<{}> = '/activity'

export const gamesPath: UrlPath<{}> = '/games'
export const viewGamePath: UrlPath<{}> = '/games'
export const viewGamePagePath: UrlPath<{}> = '/games/:gameId'
export const viewGameRequestPagePath: UrlPath<{}> =
  '/games/:gameId&browserBasedGame=true'

export const launchpadPath: UrlPath<{}> = '/launchpad'
export const launchpadReqPath: UrlPath<{}> = '/launchpad/requests'
export const launchpadPagePath: UrlPath<{}> = '/launchpad/requests/:requestId'
export const viewLaunchpadPath: UrlPath<{}> = '/launchpad/:id'
export const createLaunchpadPagePath: UrlPath<{}> = '/launchpad-create'

export const assetsPath: UrlPath<{}> = '/assets'
export const viewAssetPath: UrlPath<{}> = '/assets/view'
export const viewAssetPagePath: UrlPath<{}> = '/assets/view/:assetId'

export const notificationsPath: UrlPath<{}> = '/notifications'
export const editNotificationPath: UrlPath<{}> =
  '/notifications/:notificationSlug'

export const configurationPath: UrlPath<{}> = '/configuration'
export const newCategoryPath: UrlPath<{}> = '/configuration/create-category'
export const categorySettingsPath: UrlPath<{}> =
  '/configuration/category-settings'
export const editCategoryPath: UrlPath<{}> = '/configuration/edit-category'
export const editCategoryPagePath: UrlPath<{}> =
  'configuration/edit-category/:categorySlug'
export const createFAQPath: UrlPath<{}> = '/configuration/create-faq'
export const editFAQPath: UrlPath<{}> = '/configuration/edit-faq'
export const editFAQPagePath: UrlPath<{}> = '/configuration/edit-faq/:faqId'

export const createGlossaryPath: UrlPath<{}> = '/configuration/create-glossary'
export const editGlossaryPath: UrlPath<{}> = '/configuration/edit-glossary'
export const editGlossaryPagePath: UrlPath<{}> =
  '/configuration/edit-glossary/:glossaryId'

export const createKBAPath: UrlPath<{}> =
  '/configuration/create-security-question'
export const editKBAPath: UrlPath<{}> = '/configuration/edit-security-question'
export const editKBAPagePath: UrlPath<{}> =
  '/configuration/edit-security-question/:questionId'

export const createBlogsPath: UrlPath<{}> = '/configuration/blogs'
export const createEducationPath: UrlPath<{}> = '/configuration/education'
export const editBlogsPath: UrlPath<{}> = '/configuration/blogs/:blogId'
export const editEducationPath: UrlPath<{}> = '/configuration/education/:blogId'

export const createTermsPath: UrlPath<{}> = '/configuration/:terms'
export const editTermsPath: UrlPath<{}> = '/configuration/:terms/:termsId'

export const EditAccountDeleteContentPath: UrlPath<{}> =
  '/configuration/account-deletion/:contentId'

export const transactionLogPath: UrlPath<{}> = '/transaction-log'
export const totalSalesPath: UrlPath<{}> = '/total-sales'
export const feesGeneratedPath: UrlPath<{}> = '/fees-generated'
export const activeUsersPath: UrlPath<{}> = '/active-users'

//starter pack
export const starterPackPath: UrlPath<{}> = '/starter-pack'
export const createStarterpackPath: UrlPath<{}> =
  '/starter-pack?tab=create-starter-pack'

//sub admin
export const subAdminPath: UrlPath<{}> = '/sub-admin'

export const questPath: UrlPath<{}> = '/quest'
export const editQuestPath: UrlPath<{}> = '/edit-quest'
export const editQuestWithIdPath: UrlPath<{}> = '/edit-quest/:questId'
export const newQuestPath: UrlPath<{}> = '/new-quest'
export const viewQuestPagePath: UrlPath<{}> = '/quest/:questId'
export const questRequestPath: UrlPath<{}> = '/quest-request'
export const viewQuestRequestPagePath: UrlPath<{}> = `${questRequestPath}/:questId`
export const viewQuestRequestChangesPagePath: UrlPath<{}> = `${questRequestPath}/view-changes/:questId`
export const questCreateLogicGroupPath: UrlPath<{}> = '/create-logic-group'
export const questEditLogicGroupPath: UrlPath<{}> =
  '/quest/edit-logic-group/:logicGroupId'

export const questProfilePath: UrlPath<{}> = '/quest-profile/:questId'
export const eventDetailsPath: UrlPath<{}> = 'quest/event/:eventsId'
export const questEventDetailsRoute: UrlPath<{}> = '/quest/logic'
export const questEventDetailsPath: UrlPath<{}> = `${questEventDetailsRoute}/:questId/:logicGrpId`
export const editGameDetailsPath: UrlPath<{}> = '/games/:gameId/edit-game'
export const editGamePath: UrlPath<{}> = 'edit-game'
export const viewGameChangesPath: UrlPath<{}> = 'games/:gameId/view-changes'

//web carousel paths
export const editCarouselPagePath: UrlPath<{}> =
  'configuration/edit-carousel-page/:carouselPageId'

export const webCarouselPath: UrlPath<{}> = '/web-carousel'
export const newBannerPath: UrlPath<{}> = '/web-carousel/create-slider'
export const editBannerPath: UrlPath<{}> = '/web-carousel/edit-slider/:sliderId'
export const viewWebCarouselRequestPagePath: UrlPath<{}> =
  'web-carousel/request/:sliderId'
export const viewWebCarouselRequestChangesPagePath: UrlPath<{}> = `${webCarouselPath}/request/view-changes/:sliderId`

// floating web carousel

export const editFloatingCarouselPagePath: UrlPath<{}> =
  'configuration/edit-floating-carousel-page/:carouselPageId'

export const webFloatingCarouselPath: UrlPath<{}> = '/floating-web-carousel'
export const newFloatingBannerPath: UrlPath<{}> =
  '/floating-web-carousel/create-slider'
export const editFloatingBannerPath: UrlPath<{}> =
  '/floating-web-carousel/edit-slider/:sliderId'
export const viewFloatingWebCarouselRequestPagePath: UrlPath<{}> =
  'floating-web-carousel/request/:sliderId'
export const viewFloatingWebCarouselRequestChangesPagePath: UrlPath<{}> = `${webFloatingCarouselPath}/request/view-changes/:sliderId`

export const analyticsPath: UrlPath<{}> = '/analytics'
export const activityReportPath: UrlPath<{}> = '/activity-report'
export const activityReportFiltersPath: UrlPath<{}> = '/activity-report/filters'
export const userReportPath: UrlPath<{}> = '/user-report'
export const userReportFiltersPath: UrlPath<{}> = '/user-report/filters'
export const userCustomReportTablePath: UrlPath<{}> = '/user-report/custom'
export const activityCustomReportTablePath: UrlPath<{}> =
  '/activity-report/custom'
export const userReportTablePath: UrlPath<{}> = '/user-report/:filterId'
export const userReportEditFilterPath: UrlPath<{}> =
  '/user-report/edit-filter/:filterId'
export const activityReportEditFilterPath: UrlPath<{}> =
  '/activity-report/edit-filter/:filterId'
export const activityReportTablePath: UrlPath<{}> = '/activity-report/:filterId'
export const userReportCreateFilterPath: UrlPath<{}> =
  '/user-report/create-custom-filter'
export const activityReportCreateFilterPath: UrlPath<{}> =
  '/activity-report/create-custom-filter'
export const publisherReportsPath: UrlPath<{}> =
  '/report/configuration/:publisherId'
export const questReportPath: UrlPath<{}> = '/quest-report/:userId'

export const pathConfig: any = {
  players: playersPath,
  games: gamesPath,
  assets: assetsPath,
  publisher: gamePublisherPath,
  activity: activityPath,
  notifications: notificationsPath,
  configuration: configurationPath,
  launchpad: launchpadPath,
  starterPack: starterPackPath,
  quest: questPath,
  questProfilePath: questProfilePath,
  'category-settings': categorySettingsPath,
}
