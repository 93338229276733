import InvalidImagePlaceholder from 'assets/images/invalid-image-placeholder.png'
import imageCompression, { Options } from 'browser-image-compression'
import toast from 'react-hot-toast'
import { UploadImageToS3 } from 'utils/apis'
import { mediaTypes } from 'utils/enums'

export const handleImageError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  const target = event.target as HTMLImageElement
  target.src = InvalidImagePlaceholder // Set alternative image source
}
export const getFileType = (_file: any): string => {
  const { name } = _file
  const nameSplits = name.split('.')
  const ext = nameSplits[nameSplits.length - 1]
  return ext
}

export function bytesToKB(bytes: number) {
  if (bytes < 1024) return `1 KB`
  return Math.floor(bytes / 1024) + ' KB'
}
export type AllowedExtensions = 'image' | 'video' | 'json'

export const validateFile = (
  _file: any,
  limit_size: number = 15,
  type: AllowedExtensions = 'image'
): [boolean, string] => {
  if (!_file || _file.length === 0) {
    return [false, 'No File Selected']
  }

  let status = true
  let msg = 'success'

  const allowedExtensionsMap: { [key in AllowedExtensions]: string[] } = {
    image: ['png', 'svg', 'jpeg', 'jpg', 'gif', 'jfif'],
    video: [
      'mp4',
      'avi',
      'mkv',
      'mov',
      'wmv',
      'webm',
      'mpg',
      'mpeg',
      '3gp',
      'm4v',
    ],
    json: ['json'],
  }

  for (const element of _file) {
    const one_byte_to_mb = 1 / 1000000
    const file_size = one_byte_to_mb * element.size

    if (file_size > limit_size) {
      status = false
      msg = `Please upload ${
        type === 'video' ? 'a video' : 'an image'
      } less than ${limit_size}mb`
    }

    const { name } = element
    const nameSplits = name.split('.')
    const ext = nameSplits[nameSplits.length - 1].toLowerCase()

    const allowedExtensions = allowedExtensionsMap[type]

    if (!allowedExtensions.includes(ext)) {
      status = false
      msg = `Please upload only ${allowedExtensions.join('/')} files`
    }
  }
  return [status, msg]
}

export const getOptimizedImage = async (file: File) => {
  if (!file) {
    throw new Error('File is required')
  }
  if (!file.type.startsWith('image/')) {
    throw new Error('Image file is required')
  }
  try {
    const options: Options = {
      maxSizeMB: 2.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    }
    const compressedFile = await imageCompression(file, options)
    return compressedFile
  } catch (error) {
    console.error(error)
  }
}

export const handleFileUpload = async (
  file: any,
  mediaType?: mediaTypes,
  setProgress: () => void = () => {}
): Promise<[boolean, string]> => {
  try {
    const s3Res = await UploadImageToS3(file, setProgress, mediaType)
    return [true, s3Res]
  } catch (error) {
    console.error('Error in upload', error)
    return [false, 'Something went wrong, please try again']
  }
}

export const uploadCover = async (
  selectedFile: any,
  isLoading: boolean,
  setIsLoading: (loading: boolean) => void,
  mediaType?: mediaTypes
): Promise<string> => {
  if (isLoading) return ''
  setIsLoading(true)
  try {
    const [status, msg] = await handleFileUpload(selectedFile, mediaType)
    if (!status) {
      toast.error(msg)
      return ''
    }
    return msg
  } catch (error) {
    toast.error('Something went wrong, please try again')
    return ''
  } finally {
    setIsLoading(false)
  }
}

export const calculatePayloadSizeInMB = <T extends object>(data: T): number => {
  const payload = JSON.stringify(data)
  if (payload !== undefined || payload !== null) {
    const sizeInBytes = new TextEncoder().encode(payload).length
    const sizeInMegabytes = sizeInBytes / 1048576 // Convert bytes to megabytes
    return sizeInMegabytes
  } else return 0
}
