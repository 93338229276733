import { Col, Row } from 'shared/styled'
import styled from 'styled-components'

export const AssetImage = styled.img`
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.borderColor};
  object-fit: cover;
`

export const DetailItem = styled(Row)`
  display: flex;
  width: 100%;
  min-width: 223px;
  max-width: 223px;
  justify-content: start;

  .details-icon {
    height: 20px;
    width: 20px;
  }
`

export const TableContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 6px;
  .viewDetailsText {
    cursor: pointer;
  }

  .game_name {
    color: ${(props) => props.theme.primary};
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  thead {
    .boldHeader {
      color: ${(props) => props.theme.tableRowText};
      font-family: Poppins;
      font-size: 13px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0px;
    }

    tr {
      border-radius: 5px;
      background: ${(props) => props.theme.borderColor};
    }
    th {
      padding: 0px 10px;
      height: 54px;
    }
  }

  tbody tr:last-child {
    border: none;
  }
  tbody {
    tr {
      border-bottom: 1px solid ${(props) => props.theme.borderColor};
      transition: 0.2s linear;
      :hover {
        background-color: #e7e7e7;
      }
    }
    td {
      padding: 10px;
      height: 50px;
    }
  }
`

export const UserImg = styled.img`
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50px;
`

export const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 336px;
`
export const ParentStatus = styled.div`
  padding: 3px 8px;
  border-radius: 50px;
  font-size: 8px;
  background-color: ${(props) => props.theme.parentStatusBackgroundColor};
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 28px;
  padding-bottom: 96px;
  svg:hover {
    cursor: pointer;
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 25px;
  .saveButton {
    button :hover {
      svg > g > path {
        fill: ${(props) => props.theme.secondary};
      }
    }
  }
`

export const BorderedContainer = styled(Col)`
  width: 100%;
  /* border: 1px solid ${(props) => props.theme.borderColor}; */
  border-radius: 6px;
`
export const HeadingContainer = styled(Row)`
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  .addButton {
    button {
      svg > path {
        stroke: ${(props) => props.theme.error};
      }
    }

    button:hover {
      svg > path {
        stroke: ${(props) => props.theme.secondary};
      }
    }
  }
  /* background: ${(props) => props.theme.borderColor}; */

  .customize-table {
    cursor: pointer;
  }
`
export const ButtonContainer = styled(Row)`
  align-items: center;
  justify-content: end;
  padding: 18px 0;
  gap: 16px;

  .addButton {
    button {
      svg > path {
        stroke: ${(props) => props.theme.error};
      }
    }

    button:hover {
      svg > path {
        stroke: ${(props) => props.theme.secondary};
      }
    }
  }

  .saveButton {
    button :hover {
      svg > g > path {
        fill: ${(props) => props.theme.secondary};
      }
    }
  }
`
