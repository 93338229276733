import { PayloadAction, createReducer } from '@reduxjs/toolkit'
import {
  clearUserReportFilters,
  keepOnlyKeysUserReport,
  setInitialUserReportFilters,
  setUserReportFilters,
  updateUserReportFilters,
} from '../actions/userReport'
import { filters } from 'modules/pages/userReport/filterConstants'

// Define the type for the filter value
interface FilterValue {
  selected: boolean
  value?: string | boolean | { min: number; max: number; currency?: string }
}

// Define the state type, which is an object with string keys and FilterValue value
interface UserReportFiltersState {
  [key: string]: FilterValue
}

type UserReportState = UserReportFiltersState | null

const result = filters.reduce((acc, filter) => {
  const { dataKey } = filter

  if (dataKey) {
    if (dataKey === 'currency_type') {
      // @ts-ignore
      acc[dataKey] = { selected: true, value: 'ETH' }
    } else {
      // @ts-ignore
      acc[dataKey] = { selected: true, value: null }
    }
  }
  return acc
}, {})

// Define the initial state with the type UserReportState
const initialState: UserReportState = result

const userReportFilterReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUserReportFilters, (state: any, action) => {
    if (state === null) {
      return {
        ...action.payload,
      }
    } else if (state) {
      const newState = { ...state }

      // Iterate through the payload properties
      for (const key in action.payload) {
        if (action.payload.hasOwnProperty(key)) {
          const payloadItem = action.payload[key]
          if (payloadItem.selected === false) {
            // Remove the property from the state if selected is false
            delete newState[key]
          } else {
            // Otherwise, merge the property into the state
            newState[key] = payloadItem
          }
        }
      }

      return newState
    }
    return state
  })
  //@ts-ignore
  builder.addCase(
    updateUserReportFilters,
    (
      state: UserReportState | null,
      action: PayloadAction<{ dataKey: string; value: any }>
    ) => {
      const { dataKey, value } = action.payload

      if (state?.[dataKey]) {
        // If dataKey exists, update the value
        state[dataKey] = {
          ...state[dataKey],
          value: value,
        }
      } else {
        if (state === null) {
          // If dataKey does not exist, add a new entry
          return {
            [dataKey]: {
              selected: true,
              value: value,
            },
          }
        } else {
          state[dataKey] = {
            selected: true,
            value: value,
          }
        }
      }

      // Check if dataKey is 'currency_type' to update the currency in other fields
      if (dataKey === 'currency_type') {
        const newCurrency = value
        Object.keys(state).forEach((key) => {
          //@ts-ignore
          if (key !== 'currency_type' && state[key]?.value?.currency) {
            //@ts-ignore
            state[key].value.currency = newCurrency
          }
        })
      }
    }
  )

  // Add case for clearUserReportFilters
  //@ts-ignore
  builder.addCase(clearUserReportFilters, () => {
    return {
      last_login: { selected: true },
      currency_type: { selected: false, value: 'ETH' },
    }
  })

  //@ts-ignore
  builder.addCase(setInitialUserReportFilters, () => {
    return initialState
  })
  builder.addCase(keepOnlyKeysUserReport, (state: any, action: any) => {
    console.log('state', state, action)

    const keysToKeep = action.payload
    Object.keys(state).forEach((key) => {
      if (!keysToKeep.includes(key)) {
        delete state[key]
      }
    })
  })
})

export default userReportFilterReducer
