import { createReducer } from '@reduxjs/toolkit'
import {
  clearProfileDetail,
  setProfileDetail,
  setProfileDetailLoading,
} from '../actions'
import { IUserState } from 'utils/type'

export const initialState: IUserState = {
  profileDetail: null,
  loading: true,
}

const adminReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setProfileDetailLoading, (state, payload) => {
      state.loading = payload.payload
    })
    .addCase(clearProfileDetail, (state) => {
      state.profileDetail = null
      state.loading = false
    })
    .addMatcher(
      (action) => setProfileDetail.fulfilled.match(action),
      (state, action) => {
        state.profileDetail = action.payload
        state.loading = false
      }
    )
})

export default adminReducer
