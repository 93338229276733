import { useEffect, useState } from 'react'
import { BoldText4 } from 'shared/typography'
import { withTheme } from 'styled-components'
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxWrapper,
  Checkmark,
  Icon,
} from './style'

interface CheckboxProps {
  label: string
  checked?: boolean
  onChange: (checked: boolean) => void
  isPrimary?: boolean
  isDisable?: boolean
  className?: string
  isError?: boolean
  theme: any
  testId?: string
  disableToggle?: boolean
  img?: string
}

export const Checkbox = withTheme(
  ({
    label,
    checked,
    onChange,
    isDisable,
    isError,
    className,
    isPrimary,
    theme,
    testId,
    img,
    disableToggle,
  }: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean>(Boolean(checked))

    const handleCheckboxChange = () => {
      // const newValue = !isChecked
      if (!disableToggle) {
        setIsChecked(!isChecked)
      }
      onChange?.(!isChecked)
    }

    useEffect(() => {
      setIsChecked(Boolean(checked))
    }, [checked])

    return (
      <CheckboxWrapper
        isDisable={isDisable ?? false}
        className={className}
        data-testid={testId}
      >
        <CheckboxInput
          type='checkbox'
          checked={isChecked}
          onChange={handleCheckboxChange}
          disabled={isDisable ?? false}
        />
        <Checkmark isDisable={isDisable ?? false} className='checkmark' />

        <CheckboxLabel>
          {Boolean(img) && <Icon src={img} alt='icon' />}
          <BoldText4
            textWrap='nowrap'
            color={isDisable && theme.disableColor}
            textTransform='capitalize'
          >
            {label}
          </BoldText4>
        </CheckboxLabel>
      </CheckboxWrapper>
    )
  }
)
