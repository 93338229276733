import React, {
  JSXElementConstructor,
  ReactElement,
  forwardRef,
  useRef,
  useState,
} from 'react'
import DropDownArrow from 'assets/icons/circular-arrow-down.svg'
import placeholderIcon from 'assets/images/invalid-image-placeholder.png'

import {
  DropdownContainer,
  DropdownContent,
  DropdownHeader,
  DropdownListItem,
  IconContainer,
} from './style'
import { useClickOutside } from 'shared/hooks/useClickOutSide'
import { Col, Row } from 'shared/styled'
import SearchBar from 'shared/SearchBar'
import { Loader2 } from 'shared/loader/Loader2'
import { Text4 } from 'shared/typography'
import { handleImageError } from 'utils/helpers/fileHelpers'
import { trimString } from 'utils/helpers/stringHelpers'
import { Checkbox } from 'shared/checkbox'

interface IDropdown<T> {
  options?: T[]
  headerText?: string
  onChange?: (changed: T) => void
  onCheckBoxChange?: (changed: T[]) => void
  className?: string
  icon?: string | React.ReactElement
  hideIcon?: boolean
  headerClassName?: string
  testId?: string
  isDisabled?: boolean
  headerIcon?: string
  dropDownRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
  listClassName?: string
  searchClassName?: string
  onSearchChange?: React.ChangeEventHandler<HTMLInputElement>
  searchText?: string
  listLoading?: boolean
  showSearchBar?: boolean
  disableTrimHeader?: boolean
  checkBoxMode?: boolean
}

const Dropdown = forwardRef<HTMLDivElement, IDropdown<any>>((props) => {
  const {
    headerText,
    onChange,
    options,
    className,
    icon,
    hideIcon,
    headerClassName,
    testId,
    isDisabled,
    headerIcon,
    dropDownRef,
    listClassName,
    onSearchChange,
    searchClassName,
    listLoading,
    showSearchBar,
    disableTrimHeader,
    checkBoxMode,
    onCheckBoxChange,
    searchText,
  } = props

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const ref = useRef<HTMLElement>()

  const [checkedItems, setCheckedItems] = useState<any[]>([])

  useClickOutside(ref, setDropdownOpen)

  const renderIcon = (
    hideIcon: boolean | undefined,
    icon:
      | string
      | ReactElement<any, string | JSXElementConstructor<any>>
      | undefined
  ) => {
    if (hideIcon) {
      return null
    }

    if (icon) {
      return typeof icon === 'string' ? (
        <img src={icon} alt='switch-icon' className='switchIcon' />
      ) : (
        <>{icon}</>
      )
    }

    return null // If icon is not provided, don't render anything
  }

  return (
    <DropdownContainer
      ref={(el: HTMLDivElement) => {
        if (!el) return
        ref.current = el
      }}
      className={className}
      data-testid={testId}
    >
      {renderIcon(hideIcon, icon)}

      <DropdownHeader
        onClick={() => {
          !isDisabled && setDropdownOpen(!dropdownOpen)
        }}
        showOptions={dropdownOpen}
        className={headerClassName}
        disabled={isDisabled}
        data-testid={`test-head-${headerText}`}
      >
        <Row align='center' gap='10px' style={{ background: 'inherit' }}>
          {headerIcon && <IconContainer src={headerIcon} alt={headerText} />}
          {disableTrimHeader ? headerText : trimString(headerText ?? '', 20)}
        </Row>
        <img className='arrowDownIcon' src={DropDownArrow} alt='arrow-icon' />
      </DropdownHeader>
      {showSearchBar || options?.length ? (
        <DropdownContent
          data-testid='drop-down-list-wrap'
          ref={dropDownRef}
          showOptions={dropdownOpen}
          hideIcon={hideIcon}
          className={listClassName}
          overFlow='auto'
        >
          {dropdownOpen && (
            <>
              {showSearchBar && (
                <Col padding='8px' align='center'>
                  <SearchBar
                    placeholder='Search...'
                    inputClassName='input-wrap'
                    className={`search-input ${searchClassName}`}
                    onChange={onSearchChange}
                    borderRadius='4px'
                    data-testid='drop-search'
                    value={searchText}
                  />
                </Col>
              )}
              <Col align='center'>{listLoading && <Loader2 />}</Col>

              {options?.map((item: any, index: number) => (
                <DropdownListItem
                  enabled={item?.isEnabled ?? true}
                  // className={headerClassName}
                  data-testid={`test-${item?.slug ?? item?.value}`}
                  key={item?.value || item?.slug}
                  onClick={(e) => {
                    e?.stopPropagation()
                    e?.preventDefault()
                    if (checkBoxMode) {
                      const elementExist = Boolean(
                        checkedItems?.find(
                          (ckItem: any) => ckItem?.value === item?.value
                        )
                      )

                      const newCheckedItems = elementExist
                        ? checkedItems?.filter(
                            (ckItem: any) => ckItem?.value !== item?.value
                          )
                        : [...checkedItems, item]

                      setCheckedItems(newCheckedItems)
                      onCheckBoxChange?.(newCheckedItems)
                    } else if (item?.isEnabled ?? true) {
                      onChange?.(item)
                      setDropdownOpen(false)
                    }
                  }}
                >
                  {checkBoxMode ? (
                    <Row>
                      {/* @ts-ignore */}
                      <Checkbox
                        checked={Boolean(
                          checkedItems?.find(
                            (ckItem: any) => ckItem?.value === item?.value
                          )
                        )}
                        disableToggle
                        label={item.name}
                      />
                    </Row>
                  ) : (
                    <Row className='text-wrap' align='center' gap='10px'>
                      {item?.icon && (
                        <IconContainer
                          onError={handleImageError}
                          src={item?.icon || placeholderIcon}
                          alt={item?.icon}
                        />
                      )}
                      {item.name || item?.label}
                      {item?.message ? ` - ( ${item?.message} )` : null}
                    </Row>
                  )}
                </DropdownListItem>
              ))}

              {showSearchBar
                ? options?.length === 0 && (
                    <Col padding='6px 12px'>
                      <Text4>Not found</Text4>
                    </Col>
                  )
                : null}
            </>
          )}
        </DropdownContent>
      ) : null}
    </DropdownContainer>
  )
})

export default Dropdown
