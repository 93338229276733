import { Col } from 'shared/styled'
import { EmptyProductDiv } from './style'
import { ReactComponent as Empty } from 'assets/icons/keyboard-open.svg'
import { Heading4, Text3 } from 'shared/typography'

interface INoData {
  message?: string
  headingText?: string
  width?: string
  height?: string
}

const NoData = (props: INoData) => {
  const { message, headingText, width, height } = props
  return (
    <EmptyProductDiv
      width={width}
      height={height}
      as={Col}
      align='center'
      justify='center'
      gap='20px'
      data-testid='empty-product-div'
    >
      <Empty height={60} width={54} data-testid='empty-icon' />
      <Col align='center' gap='6px'>
        <Heading4 textAlign='center' data-testid='heading'>
          {headingText ?? 'No Results Found!'}
        </Heading4>
        <Text3 textAlign='center'>
          {message ?? "We couldn't find anything with this criteria"}
        </Text3>
      </Col>
    </EmptyProductDiv>
  )
}

export default NoData
