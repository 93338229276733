import { setDownloadReportApi } from 'logic/redux/actions/downloadReport'
import { useAppDispatch, useAppSelector } from 'logic/redux/hooks'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { toast } from 'react-hot-toast'
import { Button } from 'shared/button'
import CustomModal from 'shared/custom-modal-responsive'
import { Col, Row } from 'shared/styled'
import { SuccessLottieAnimation } from 'shared/successLottieAnimation/SuccessLottieAnimation'
import { Heading2, Text2 } from 'shared/typography'
import styled from 'styled-components'
import { screenSizes } from 'styles/theme'
import { SUCCESS_LOTTIE_PATH } from 'utils'

interface Props {
  children?: React.ReactNode
}

const DownloadWrapper = (props: Props) => {
  const { children } = props

  const dispatch = useAppDispatch()

  const {
    downloadReportApi,
    downloadReportFilters,
    downloadReportFileName,
    processedList,
    tableHeadersList,
  } = useAppSelector((state) => state.downloadReportReducer)
  const { ethPrice, solPrice } = useAppSelector((state) => state?.priceReducer)
  const [csvData, setCsvData] = useState<any>([])
  const [totalSalesList, setTotalSalesList] = useState<any>([])
  const [showWarnings, setShowWarnings] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(false)
  const [csvFromBackend, setCsvFromBackend] = useState<boolean>(false)
  const [csvLik, setCsvLik] = useState()

  const getDownloadData = async () => {
    if (loader) {
      toast.error(
        'Sorry, a report is currently being generated. Please try again in a moment.'
      )
      return
    }
    setLoader(true)

    try {
      const response = await downloadReportApi(downloadReportFilters)
      if (response?.file_url ?? response?.url) {
        setCsvFromBackend(true)
        setCsvLik(response?.file_url ?? response?.url)
      } else {
        setTotalSalesList(response?.result)
      }
    } catch (error) {
      console.error('error', error)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    if (downloadReportApi) getDownloadData()
  }, [downloadReportApi])

  // In your component
  useEffect(() => {
    if (typeof processedList === 'function') {
      const filteredArray = processedList?.(
        totalSalesList,
        Number(ethPrice),
        Number(solPrice)
      )

      setCsvData(filteredArray)
    }
  }, [totalSalesList, ethPrice, solPrice])

  const handleResetData = () => {
    setCsvData([])
    dispatch(setDownloadReportApi(null, null, '', [], []))
    setShowWarnings(false)
    setCsvLik(undefined)
    setCsvFromBackend(false)
  }

  return (
    <>
      {children}
      <CustomModal
        show={csvData?.length || !!csvLik}
        showCloseButton
        toggleModal={() => {
          if (showWarnings) handleResetData()
          else setShowWarnings(true)
        }}
      >
        <SuccessSubContainer>
          {showWarnings ? (
            <Col gap='24px' align='center'>
              <Col gap='16px'>
                <Heading2>Are you sure you want to close?</Heading2>
                <Text2>
                  Closing this modal will remove your progress. You will not
                  able to download the generated report.
                </Text2>
              </Col>

              <Row gap='16px'>
                <Button
                  btnType='secondary'
                  width='251px'
                  onClick={() => setShowWarnings(false)}
                >
                  Cancel
                </Button>

                <Button
                  btnType='primary'
                  width='251px'
                  onClick={() => handleResetData()}
                >
                  Close
                </Button>
              </Row>
            </Col>
          ) : (
            <>
              <SuccessLottieAnimation path={SUCCESS_LOTTIE_PATH} />
              <Col gap='24px' align='center'>
                <Col gap='16px'>
                  <Heading2>Your Report is generated</Heading2>
                </Col>

                {csvFromBackend ? (
                  <Button btnType='secondaryS'>
                    <a href={csvLik}>Download report</a>
                  </Button>
                ) : (
                  <CSVLink
                    data={csvData}
                    headers={tableHeadersList}
                    filename={downloadReportFileName}
                    // onClick={() => handleResetData()}
                  >
                    <Button btnType='secondaryS'>Download report</Button>
                  </CSVLink>
                )}
              </Col>
            </>
          )}
        </SuccessSubContainer>
      </CustomModal>
    </>
  )
}

export default DownloadWrapper

const SuccessSubContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  width: 100%;

  a {
    color: inherit;
  }

  @media (min-width: ${screenSizes.mediaSM}px) {
    min-width: 528px;
    max-width: 528px;
  }
`
